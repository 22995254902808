import { LoginService } from '../services/login.service';
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { WebsiteConstants } from '.././global/website-constants';
import * as AOS from 'aos';
import { LogoutService } from '../services/logout.service';

@Component({
  selector: 'app-home-top',
  templateUrl: './home-top.component.html',
  styleUrls: ['./home-top.component.css']
})
export class HomeTopComponent implements OnInit {

  agelist:any=[]; ageto:any=[]; link:any; downloadlink:any; SiteUrl = WebsiteConstants.SiteUrl;
  usersession:any; candidatename:any; memberOtp:any;
  isusertopauth:any=true;
  constructor(private route: Router,private service:LoginService,private logoutservice:LogoutService) { }

  ngOnInit(): void {
    AOS.init();
    this.displayAppVersion();
    for(let i=21;i<=50;i++)
      {
        this.agelist.push(i)
      }

      for(let j=21; j<=50;j++)
      {
        this.ageto.push(j);
      }

      this.usersession = localStorage.getItem("userid");
      this.candidatename = localStorage.getItem("UserFullName");
      this.memberOtp = localStorage.getItem("MemberOTPVerified");
        if(this.memberOtp)
        {
           this.isusertopauth = false;
        }
  }

  redirectLogin()
  {
    let link = ['/login'];
    this.route.navigate(link);
  }

  redirect(flag)
  {
    
    if(flag=='reg')
    {
       this.link = ['/registration'];
    }
    else
    {
       this.link = ['/login'];
    }
    this.route.navigate(this.link);
  }
  

  logout()
  {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    let loggedoutuser = localStorage.getItem('UserMemberID');
    body.append('LoggedUserid', loggedoutuser);

    this.logoutservice.logoutUrl(body,headers).subscribe((Response) =>{
    localStorage.clear();
    let link = ['/login'];
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate(link);
       
    });

    })
   

  }

  displayAppVersion()
  {
    let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('CasteID', '5');

          this.service.appversionUrl(body,headers).subscribe(Result =>{
            console.log(Result)
          if(Result['status']==1) 
          { 
               localStorage.setItem('DownloadLink',Result['data'][0].AppURL);
          } 


        })
  }


  playStore()
  {
        let openLink = localStorage.getItem('DownloadLink')
        window.open(openLink);
  }

}
