<div class="footerMain">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="ftDiv">
                    <ul>
                        <li><a href="#">Need Help?</a></li>
                        <li><a href="#" [routerLink]="['/login']">Partner Search</a></li>
                        <li><a href="#" [routerLink]="['/registration']">Sign Up</a></li>
                        <li><a href="#" [routerLink]="['/plans']">Premium Memberships</a></li>
                    </ul>
                </div>
            </div>
  
            <div class="col-md-4 col-sm-4">
                <div class="ftDiv">
                    <ul>
                        <li><a href="#">Company</a></li>
                        <li><a href="#" [routerLink]="['/about-us']">About Us</a></li>
                        <li><a href="#" [routerLink]="['/contact-us']">Contact Us</a></li>
                    </ul>
                </div>
            </div>
  
            <div class="col-md-4 col-sm-4">
                <div class="ftDiv ftDiv2">
                    <ul>
                        <li><a>Privacy & You</a></li>
                        <li><a href="terms-use" target="_blank">Terms of Use</a></li>
                        <li><a href="privacy-policy" target="_blank">Privacy Policy</a></li>
                    </ul>
                    <div class="socialDiv">
                        <a href="https://www.facebook.com/LingayatReshimgathi" target="_blank"> <i class="fa fa-facebook"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  

  </div>
  <div class="copyWriMain">
    Copyright © 2017 <span style="color: #ff006c">Lingayat Reshimgathi</span> All Rights Reserved.
</div>

