import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MembershipPlanService} from '../services/membership-plan.service';
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { WebsiteConstants } from '.././global/website-constants';

@Component({
  selector: 'app-payment-new',
  templateUrl: './payment-new.component.html',
  styleUrls: ['./payment-new.component.css']
})
export class PaymentNewComponent implements OnInit {
  public payuform: any = {};
  disablePaymentButton: boolean = true;

  onemonth:any; threemonth:any; sixmonth:any; yearmonth:any; 
  onepktitle:any; onepkdisamt:any; onediscount:any; onepkpayamt:any;
  oneMpkgID:any; oneMpkgPeriodinDays:any;

  threepktitle:any; threepkdisamt:any; threediscount:any; threepkpayamt:any;
  threeMpkgID:any; threeMpkgPeriodinDays:any;

  sixktitle:any; sixpkdisamt:any; sixdiscount:any; sixpkpayamt:any;
  sixMpkgID:any; sixMpkgPeriodinDays:any;

  yearpktitle:any; yearpkdisamt:any; yeardiscount:any; yearpkpayamt:any;
  yearMpkgID:any; yearMpkgPeriodinDays:any;

  displaybanner:any; displaybannerflag:any="0"; logspinner=false; photopath:any;
  onepkoriamt:any; threepkoriamt:any; sixpkoriamt:any; yearpkoriamt:any; userProfileData:any;

  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private service:MembershipPlanService, private route : Router, private http: HttpClient, private memberservice : ViewmemberdetailsService) { }

  ngOnInit(): void 
  {
      this.photopath = this.masterUrl;
      this.logspinner = true;
      this.onemonth = localStorage.getItem('AppOneMonthFlag');
      this.threemonth = localStorage.getItem('AppThreeMonthFlag');
      this.sixmonth = localStorage.getItem('AppSixMonthFlag');
      this.yearmonth = localStorage.getItem('AppYearFlag');
      
          setTimeout(()=>{                           
            this.onemonthplan();
          }, 1000);
        
        setTimeout(()=>{                           
            this.threemonthplan();
          }, 1500);

        
        setTimeout(()=>{                           
            this.sixmonthplan();
          }, 2000);

        
        setTimeout(()=>{                           
            this.yearplan()
          }, 2500);


      setTimeout(()=>{                           
            this.displayofferimg()
          }, 3000);

  }


  displayofferimg()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID','5');
        this.service.upgradeplanimg(body,headers).subscribe(Response =>{
                this.displaybanner = this.photopath+Response['data']['DiscountBanner'];
                this.displaybannerflag = Response['data']['Disdisplay'];
                this.logspinner = false;
        })
  }  

  onemonthplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID','5');
        body.append('PkgPeriod','1 Month');
        this.service.oneemonthplan(body,headers).subscribe(Response =>{
          this.onepktitle=Response['data'][0]['MpkgTitle']; 
          this.onepkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
          this.onediscount=Response['data'][0]['MpkgDiscount']; 
          this.onepkpayamt=Response['data'][0]['MpkgPayAmt'];
          this.onepkoriamt=Response['data'][0]['MpkgOriginalAmt'];
          this.oneMpkgID = Response['data'][0]['MpkgID'];
          this.oneMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        
        })
  }

  threemonthplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID','5');
        body.append('PkgPeriod','3 Months');
        this.service.threeemonthplan(body,headers).subscribe(Response =>{
            this.threepktitle=Response['data'][0]['MpkgTitle']; 
            this.threepkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
            this.threediscount=Response['data'][0]['MpkgDiscount']; 
            this.threepkpayamt=Response['data'][0]['MpkgPayAmt'];
            this.threepkoriamt = Response['data'][0]['MpkgOriginalAmt'];
            this.threeMpkgID = Response['data'][0]['MpkgID'];
            this.threeMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        })

  
  }

  sixmonthplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID','5');
        body.append('PkgPeriod','6 Months');
        this.service.sixemonthplan(body,headers).subscribe(Response =>{
              this.sixktitle=Response['data'][0]['MpkgTitle']; 
              this.sixpkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
              this.sixdiscount=Response['data'][0]['MpkgDiscount']; 
              this.sixpkpayamt=Response['data'][0]['MpkgPayAmt'];
              this.sixpkoriamt = Response['data'][0]['MpkgOriginalAmt'];
              this.sixMpkgID = Response['data'][0]['MpkgID'];
              this.sixMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        })

  
  }

  yearplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID','5');
        body.append('PkgPeriod','1 Year');
        this.service.yearhplan(body,headers).subscribe(Response =>{
              this.yearpktitle=Response['data'][0]['MpkgTitle']; 
              this.yearpkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
              this.yeardiscount=Response['data'][0]['MpkgDiscount']; 
              this.yearpkpayamt=Response['data'][0]['MpkgPayAmt'];
              this.yearpkoriamt = Response['data'][0]['MpkgOriginalAmt'];
              this.yearMpkgID = Response['data'][0]['MpkgID'];
              this.yearMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        })

  }


  confirmPayment() 
  {
    let link = ['/login'];
    this.route.navigate(link);
  }

}
