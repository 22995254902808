

<div class="dashboardMain" id="activeButtDIV">
    <div class="slidebarOuter">
        <div class="slidebar">
  <div class="overDiv overDivBrows">
              <div class="contain animated bounce">
              <div class="upPicOuter">
                <label class=newbtn>
                    <img id="blah2" [src]='profilePic' >
                    <i class="fa fa-pencil pencilUpPic"></i>
                </label>
              </div>
          </div>
          <p class="proname">{{ProfileName}}</p>
          <p class="proname pronameID">({{ProfileId}})</p>
      </div>
      <ul>
        <li class="upgrdOut"><a class="upgrd" href="#upgradeView"><i class="fa fa-upgrade"></i>Upgrade to Membership</a></li>
        <li><a href="#dashboard" id="targeted"><i class="fa fa-filter"></i>Advance Search</a></li>
        <li><a href="#descoverMatches"><i class="fa fa-binoculars"></i>My Descover Matches</a></li>
        <li><a href="#updateProfile"><i class="fa fa-user-plus"></i>Update Profile</a></li>
        <li><a href="#editPreference"><i class="fa fa-edit"></i>Edit Preference</a></li>
        <li><a href="#photoGallery"><i class="fa fa-photo"></i>Photo Gallery</a></li>
        <li><a href="#" data-toggle="modal" data-target="#raeUsM"><i class="fa fa-star-half-full"></i>Rate Us</a></li>
        <li id="Drsettings"><a><i class="fa fa-cogs"></i>Settings<i class="fa fa-chevron-down" style="    float: right; line-height: 36px;"></i></a>
            <ul class="dropSetting">
              <li><a href="#changPass">Change Password</a></li>
              <li><a data-toggle="modal" data-target="#deleteAc">Delete Account</a></li>
              <li><a href="#termsAcond">Terms & Conditions</a></li>
              <li><a href="#privacyPoli">Privacy Policy</a></li>
            </ul>
        </li>
        <li><a href="#help"><i class="fa fa-question-circle"></i>Help</a></li>
        <li><a href="Javascript:void(0)" (click)="Logout()"><i class="fa fa-power-off"></i>Logout</a></li>
      </ul>
    </div>
    <div id="slideRight"><div class="fa fa-chevron-left desktopArrow rotate"></div><div class="fa fa-chevron-right responssiveArrow rotate"></div></div>
  </div>
      <div class="admin-panel clearfix">
    <div class="main">
      <div class="slideOverly"></div>
      <ul class="topbar clearfix dashTopIcon">
        <a href="/"><img src="assets/images/br-logo3.png" class="dashLogo" alt="img"></a>
        <li><a href="Javascript:void(0)" (click)="Logout()"><i class="fa fa-power-off"></i></a></li>
        <li><a href="#notification"><i class="fa fa-bell"></i></a></li>
        <li><a href="#mail"><i class="fa fa-envelope"></i></a></li>
        <li><a href="#newMatche"><i class="fa fa-home"></i></a></li>
        <li class=""><a href="#mysearch"><i class="fa fa-search"></i></a></li>
        <li class="proId">({{ProfileId}})</li>
      </ul>
         <div class="subMenu">
          <div class="custmSearch"><input class="autoSearchClick" type="text" placeholder="Search by Profile ID or Name" name=""><i class="fa fa-search subSearchBT"></i>
            <div class="autoSearchDiv">
              <h4>Recent Searches</h4>
              <div class="cityBt searchAutoBt">Deshpande</div>
              <div class="cityBt searchAutoBt">Br2322</div>
              <div class="cityBt searchAutoBt">Br2277</div>
              <div class="cityBt searchAutoBt">Londhe</div>
              <div class="autoSearchViewPro">
                <ul>
                  <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Aishwarya Deshpande</li>
                  <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Vaishnavi Deshpande</li>
                  <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Pratiksha Deshpande</li>
                </ul>
              </div>
              <div class="extraSearch">
                <span class="searchFo"><a href="#">Search For</a></span><span class="searchFo searchFoLeft"><a href="#dashboard" class="adSearch">Advance Search</a></span>
                <div class="extraSearhIn">
                  <h4>Discover Preferrd Matches By</h4>
                  <div class="extSeOut">
                    <div class="extSeIn"><i class="fa fa-map-marker"></i></div>
                    <span>Location</span>
                  </div>
  
                  <div class="extSeOut">
                    <div class="extSeIn" style="background-color: #65cafb !important"><i class="fa fa-suitcase"></i></div>
                    <span>Profession</span>
                  </div>
  
                  <div class="extSeOut">
                    <div class="extSeIn" style="background-color: #e250e7 !important">
                      <i class="fa fa-graduation-cap"></i>
                    </div>
                    <span>Education</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <header class="newheader">
            <input class="menu-btn" type="checkbox" id="menu-btn" />
            <label class="menu-icon" for="menu-btn">More Matches<i class="fa fa-chevron-down"></i><span class="navicon"></span></label>
            <ul id="topNavBar" class="menu">
              <a href="/"><img src="assets/images/br-logo3.png" class="dashLogo2" alt="img"></a>
              <li><a href="Javascript:void(0)" id="mymatchid" (click)="myMachesfun()">My Matches</a></li>
              <li><a href="Javascript:void(0)" id="mynewmatchid" (click)="newMatchesfun()">New Matches</a></li>
              <li class="dropClick"><a href="Javascript:void(0)" id="nearmeid" (click)="nearMefun()">Near Me</a>
                <!-- <div class="dropMenu">
                  <ul>
                    <li role="presentation"><a role="menuitem" tabindex="-1">Nashik</a></li>
                    <li role="presentation"><a role="menuitem" tabindex="-1">Mumbai</a></li>
                    <li role="presentation"><a role="menuitem" tabindex="-1">Pune</a></li>
                    <li role="presentation" class="divider"></li>
                    <li role="presentation"><a role="menuitem" tabindex="-1">Aurangabad</a></li>    
                  </ul>
                </div> -->
              </li>
              <li><a href="Javascript:void(0)" id="shortlistedid" (click)="shortlistedfun()">Shortlisted</a></li>
              <li><a href="Javascript:void(0)" id="viewmyprofileid" (click)="viewedMyProfilefun()">Viewed My Profile</a></li>
              <li><a href="Javascript:void(0)" id="shortlistedmeid" (click)="shortlistedMefun()">Shortlisted Me</a></li>
            </ul>
          </header>
        </div>   
      <div class="mainContent clearfix">
        <div class="fade in" id="welcomedash">
          <!-- <div class="dashMainCont">
            <div class="dashMainInner">
              <div class="welcomeDiv">
                <img src="assets/images/welcome.png">
                <h5>Welcome</h5>
              </div>
            </div>
          </div> -->
       
       
  <!-- <div class="search-reault" 
  infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()">
  <p>hiiiiii</p>
  
  <div *ngFor="let scrolldash of mymatchscrollresult"> 
      <div class="col-md-4">
        <div class="matchesDiv">
            <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
            <div class="matchesPic">
              <div class="coun">{{scrolldash.MemberCNtMultiplePhotos}}</div>
              <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
              <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                <img [src]='photopath+scrolldash.MemberPhotoPath'>
                <div *ngIf="scrolldash.MemberIsPhotoUpload === '0'">
                <button class="requestBtUp">Request Photo</button>
              </div>
                <i class="fa fa-camera phoIco"></i>
            </div>
            <div class="callDetais">
                <div class="floatNeed">
                <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="scrolldash.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="scrolldash.MemberSentInterestFlag == '0'"></i></span>
                <span data-tooltip="Shortlisted">
                  <i class="fa fa-star starIc shortLis" *ngIf="scrolldash.MemberShortlistFlag !== '0'"></i>
                  <i class="fa fa-star starIc" *ngIf="scrolldash.MemberShortlistFlag == '0'"></i>
                </span>
                </div>
  
                <div class="detailsOuter">
                <h4 class="callDetaillsName">{{scrolldash.MemberName}} ({{scrolldash.MemberProfileID}})</h4>
                <p>{{scrolldash.MemberEducation}}, Job-City - {{scrolldash.MemberJobCity}}</p>
                <p>{{scrolldash.MemberAge}} yrs, {{scrolldash.MemberHeight}} | {{scrolldash.MemberMartialStatus}}</p>
                <p>{{scrolldash.MemberCaste}}, {{scrolldash.MemberNativePlace}} </p>
              </div>
                
                <button type="button" class="proDetailsBt" (click)="memberdetailspost(scrolldash)">
                  View Profile
                </button>
            </div>
        </div>
    
  </div>
  </div>
  
  
  </div>
  
  <div class="samplecover-spin" id="samplecover-spin" *ngIf="spinner"></div> -->
  
  
       
        </div>
  
        <div id="upgradeView">
          <div class="dashMainCont">
            <div class="dashMainInner">
              <div class="row">
                <div class="col-md-12">
                  <div class="bemberShipBanner">
                    <h1>Launching offer <span class="flatOf">Flat <span style="color: #fff700;">50%</span> off</span></h1>
                  </div>
                  <div class="membershipDiv">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="memberShiInner">
                            <div class="promo">
                              <div class="deal">
                                <span>Diamond Pack</span>
                                <span>Flat Rs.400 OFF ON 1 Month PACKAGE</span>
                              </div>
                              <span class="price"><span style="font-size: 16px;
      color: #00c573;">50% OFF</span> <span class="hoverAmou"><i class="fa fa-inr"></i>400</span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"><i class="fa fa-inr"></i>800</span></span>
                              <ul class="features">
                                <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                                <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                                <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                              </ul>
                              <button class="price1">Pay Now</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="memberShiInner">
                            <div class="promo">
                              <div class="deal">
                                <span>Silver Pack</span>
                                <span>Flat Rs.800 OFF ON 3 Month PACKAGE</span>
                              </div>
                              <span class="price"><span style="font-size: 16px;
      color: #00c573;">50% OFF</span> <span class="hoverAmou"><i class="fa fa-inr"></i>800</span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"><i class="fa fa-inr"></i>1600</span></span>
                              <ul class="features">
                                <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                                <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                                <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                              </ul>
                              <button class="price2">Pay Now</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="memberShiInner">
                            <div class="promo">
                              <div class="deal">
                                <span>Gold Pack</span>
                                <span>Flat Rs.1100 OFF ON 6 Month PACKAGE</span>
                              </div>
                              <span class="price"><span style="font-size: 16px;
      color: #00c573;">50% OFF</span> <span class="hoverAmou"><i class="fa fa-inr"></i>1100</span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"><i class="fa fa-inr"></i>2200</span></span>
                              <ul class="features">
                                <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                                <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                                <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                              </ul>
                              <button class="price3">Pay Now</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="memberShiInner">
                            <div class="promo">
                              <div class="deal">
                                <span>Platinum Pack</span>
                                <span>Flat Rs.1500 OFF ON 1 Year PACKAGE</span>
                              </div>
                              <span class="price"><span style="font-size: 16px;
      color: #00c573;">50% OFF</span> <span class="hoverAmou"><i class="fa fa-inr"></i>1500</span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"><i class="fa fa-inr"></i>3000</span></span>
                              <ul class="features">
                                <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                                <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                                <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                              </ul>
                              <button class="price4">Pay Now</button>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div id="mysearch">
          <div class="dashMainCont">
            <div class="dashMainInner">
      <div class="row">
              <div class="col-md-12">
                  <div class="panel panel-default">
                      <!-- Default panel contents -->
                      <div class="panel-heading">
                          <div class="input-group">
                              <input type="hidden" name="search_param" value="name" id="search_param">
                              <input id="searchText"type="text" class="form-control autoSear" name="q" placeholder="Search your matches" id="search_key" value="">
                              <span class="input-group-btn">
                                  <a  id="x" class="btn btn-default hide myclo" href="#mysearch" title="Clear"><i class="fa fa-close glyphicon-remove"></i> </a>
                                  <button class="btn btn-info" type="submit">  GO  </button>
                              </span>
                          </div>
                      </div>
  
                      <!-- List group -->
                      <ul id="autolist" class="list-group">
                          <div id="autocompleteTest">
  
  
                          </div>
                          <li id="fav" class="list-group-item">
                              <div class="row">
                                  <div id="favorites" class="">
                                          <span class="fa fa-star glyphicon-star"> </span><b>Favorites</b>
                                  </div>
                              </div>
                          </li>
  
                          <li class="list-group-item">
                              <div class='row'>
                                  <div class='col-md-12'>
                                      <div class='media-left media-middle'>
                                          <a href='#'>
                                              <img class='media-object img-circle' src='assets/images/matches2.jpg'>
                                          </a>
                                      </div>
                                      <div id='center'>
                                          Favorite Person #1
                                          <div id='center' class='material-switch pull-right'>
                                              <input id='someSwitchOptionPrimary' name='someSwitchOption001i' type='checkbox' checked="true"/>
                                              <label for='someSwitchOptionPrimary' class='label-primary'></label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li class="list-group-item">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="media-left media-middle">
                                          <a href="#">
                                              <img class='media-object img-circle' src='assets/images/matches2.jpg'>
                                          </a>
                                      </div>
                                      <div id="center">
                                          Favorite Person #2
                                          <div id="center" class="material-switch pull-right">
                                              <div class="material-switch pull-right">
                                                  <input id="someSwitchOptionSuccess" name="someSwitchOption001" type="checkbox" checked="true"/>
                                                  <label for="someSwitchOptionSuccess" class="label-primary"></label>
                                              </div>
  
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li class="list-group-item">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="media-left media-middle">
                                          <a href="#">
                                              <img class='media-object img-circle' src='assets/images/matches2.jpg'>
                                          </a>
                                      </div>
                                      <div id="center">
                                          Favorite Person #3
                                          <div id="center" class="material-switch pull-right">
                                              <div class="material-switch pull-right">
                                                  <input id="someSwitchOptionInfo" name="someSwitchOption001" type="checkbox" checked="true"/>
                                                  <label for="someSwitchOptionInfo" class="label-primary"></label>
                                              </div>
  
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li class="list-group-item">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="media-left media-middle">
                                          <a href="#">
                                              <img class='media-object img-circle' src='assets/images/matches2.jpg'>
                                          </a>
                                      </div>
                                      <div id="center">
                                          Favorite Person #4
                                          <div id="center" class="material-switch pull-right">
                                              <div class="material-switch pull-right">
                                                  <input id="someSwitchOptionWarning" name="someSwitchOption001" type="checkbox" checked="true"/>
                                                  <label for="someSwitchOptionWarning" class="label-primary"></label>
                                              </div>
  
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li class="list-group-item">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="media-left media-middle">
                                          <a href="#">
                                              <img class='media-object img-circle' src='assets/images/matches2.jpg'>
                                          </a>
                                      </div>
                                      <div id="center">
                                          Favorite Person #5
                                          <div id="center" class="material-switch pull-right">
                                              <div class="material-switch pull-right">
                                                  <input id="someSwitchOptionDanger" name="someSwitchOption001" type="checkbox" checked="true"/>
                                                  <label for="someSwitchOptionDanger" class="label-primary"></label>
                                              </div>
  
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
      </div>
      <div class="row">
          <div class="col-md-2">
              <div class="">
                  <ul id="autocompleteTest">
  
                  </ul>
              </div>
          </div>
      </div>
  
      <div class="extraSearch">
                <span class="searchFo"><a href="#">Search For</a></span><span class="searchFo searchFoLeft"><a href="#dashboard" class="adSearch">Advance Search</a></span>
                <div class="extraSearhIn">
                  <h4>Discover Preferrd Matches By</h4>
                  <div class="extSeOut">
                    <div class="extSeIn"><i class="fa fa-map-marker"></i></div>
                    <span>Location</span>
                  </div>
  
                  <div class="extSeOut">
                    <div class="extSeIn" style="background-color: #65cafb !important"><i class="fa fa-suitcase"></i></div>
                    <span>Profession</span>
                  </div>
  
                  <div class="extSeOut">
                    <div class="extSeIn" style="background-color: #e250e7 !important">
                      <i class="fa fa-graduation-cap"></i>
                    </div>
                    <span>Education</span>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
  
        <div id="dashboard">
          <!-- <h2 class="header"><span class="icon"></span><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Advance Search</h2> -->
            <div class="dashMainCont">
              <div class="dashMainInner">
                <div class="row">
                    <div class="col-md-4">
                      <label>Age :</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                            <label>From :</label>
                          <select class="inputFil inputFilSelect">
                              <option>Aged</option>
                              <option>18</option>
                              <option>19</option>
                              <option>20</option>
                              <option>21</option>
                          </select>
  <div class="inpuDataDevider"></div>
                          <label>To :</label>
                          <select class="inputFil inputFilSelect">
                              <option>Aged</option>
                              <option>18</option>
                              <option>19</option>
                              <option>20</option>
                              <option>21</option>
                          </select>
                          <div class="doneBt">Done</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                      <label>Height</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                            <label>From :</label>
                          <select class="inputFil inputFilSelect">
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                          </select>
  <div class="inpuDataDevider"></div>
                          <label>To :</label>
                          <select class="inputFil inputFilSelect">
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                              <option>4ft 10" (147 cm)</option>
                          </select>
                          <div class="doneBt">Done</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                      <label>Marital Status</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Never Married</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Divorced</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Awaiting Divorced</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Widower</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Religion</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Hindu</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Christains</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Jain</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Caste</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Maratha</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Lingayat</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Lingayat</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Sub Caste</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Pancham</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">PanchamSali</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Dikshwant</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Gavli</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Country</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">India</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">United State of America</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>State</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Maharashtra</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Karnataka</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>City</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Ahmadnagar</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Akola</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Amravati</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Aurangabad</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Edducation</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Aviation Degree</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">B.A</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">B.Com</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">B.Ed</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Occupation</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Manager</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Supervisor</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Officer</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Administrative Professional</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Emlpoyee In</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Government</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Private</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Defence</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Self Employed</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Job City</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Nashik</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Mumbai</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Karnataka</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Aurangabad</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Annual Income</label>
                      <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                            <label>From :</label>
                          <select class="inputFil inputFilSelect">
                              <option>Any</option>
                              <option>1 lakh</option>
                              <option>2 lakh</option>
                              <option>3 lakh</option>
                              <option>4 lakh</option>
                          </select>
  <div class="inpuDataDevider"></div>
                          <label>To :</label>
                          <select class="inputFil inputFilSelect">
                             <option>Any</option>
                              <option>1 lakh</option>
                              <option>2 lakh</option>
                              <option>3 lakh</option>
                              <option>4 lakh</option>
                          </select>
                          <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Manglik</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Never Married</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Divorced</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Awaiting Divorced</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Widower</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
  
                    <div class="col-md-4">
                      <label>Mother Tongue</label>
                        <input type="text" name="" class="inputFil inputFilSlide">
                        <div class="inpuData">
                              <form>
                                <div class="form-group">
                                  <input type="checkbox" id="Any">
                                  <label for="Any">Any</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Never">
                                  <label for="Never">Never Married</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Divorced">
                                  <label for="Divorced">Divorced</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Awaiting">
                                  <label for="Awaiting">Awaiting Divorced</label>
                                </div>
                                <div class="form-group">
                                  <input type="checkbox" id="Widower">
                                  <label for="Widower">Widower</label>
                                </div>
                              </form>
                              <div class="doneBt">Done</div>
                        </div>
                    </div>
                    <div class="restSearchDiv">
                      <button class="resetSearchBt">Reset Search</button>
                      <button class="searchNowBt">Search Now</button>
                    </div>
                </div>
            </div>
            </div>
         </div>
  
         <div id="viewAllProScreen" class="myMatchesStyle">
           <div class="dashMainCont">
             <div class="dashMainInner">
               <div class="row">
                 <div class="col-md-12">
                  <div class="citySelectBt">Select City <span class="caret"></span></div>
                    <div class="descoverCityDiv">
                      <div class="cityBt">Nashik</div>
                      <div class="cityBt">Aurangabad</div>
                      <div class="cityBt">Pune</div>
                      <div class="cityBt">Nandurbar</div>
                      <div class="cityBt">Yeola</div>
                      <div class="cityBt">Shirdi</div>
                      <div class="cityBt">Malegaon</div>
                      <div class="cityBt">Nandgaon</div>
                      <div class="cityBt">Chandwad</div>
                      <div class="cityBt">Nashik</div>
                      <div class="cityBt">Aurangabad</div>
                      <div class="cityBt">Pune</div>
                      <div class="cityBt">Nandurbar</div>
                      <div class="cityBt">Yeola</div>
                      <div class="cityBt">Shirdi</div>
                      <div class="cityBt">Malegaon</div>
                      <div class="cityBt">Nandgaon</div>
                      <div class="cityBt">Chandwad</div>
                      <div class="cityBt">Chandwad</div>
                      <div class="cityBt">Nashik</div>
                    </div>
                 </div>
               </div>
               <div class="matchesInner">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/avtar-female.png">
                              <button class="requestBtUp">Request Photo</button>
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                    <ul class="pagination">
                      <li><a href="">1</a></li>
                      <li><a href="">2</a></li>
                      <li><a href="">3</a></li>
                      <li><a href="">4</a></li>
                      <li><a href="">5</a></li>
                    </ul>
                  </div>
                  </div>
                </div>
             </div>
           </div>
         </div>
  
         <div id="descoverMatches">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>My Descover Matches</h2> -->
           <div class="dashMainCont">
              <div class="dashMainInner">
                <div class="matchesInner">
  <div class="row">
    <div class="col-md-12">
      <h4 class="proHead proHead2 desMatchHead"><i class="fa fa-hospital-o"></i> City (860)</h4>
      <a href="#viewAllProScreen" class="newMViewPro desMatchViewAll">View All Profile</a>
      <div class="col-md-2 col-xs-6">
            <div class="newMatcInner">
              <div class="newMaImg"><img src="assets/images/matches2.jpg"></div>
              <P>Shubha Gaikwad</P>
              <p>R1789836</p>
              <p>26 yrs, 5.5 ft</p>
              <p>96 Kuli Maratha, Mumbai</p>
              <a href="#proDetails">View Profile</a>
            </div>
          </div>                        
    </div>
  </div>
  
  
                 
                </div>
              </div>
            </div>
         </div>
         <div id="updateProfile">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Update Profile</h2> -->
           <div class="dashMainCont">
             <div class="dashMainInner">
               <div class="proDetailsDiv">
                <div class="proDi">
                  <div class="overDiv overDivBrows">
                <div class="contain animated bounce">
                  <form id="form1" runat="server">
                      <div id='img_contain'><img id="blah" align='middle' src="assets/images/default-avatar.jpg" alt="your image" title=''/></div> 
                      <div class="input-group"> 
                      <div class="custom-file">+
                      <input type="file" id="inputGroupFile01" class="imgInp custom-file-input" aria-describedby="inputGroupFileAddon01">
                      </div>
                      </div>
                  </form>
                </div>
                <p class="proname">Prem Gaikwad (200WR)</p>
                  </div>
                  <div class="comlpetedProDiv">
                    <input type="radio" class="radio" name="progress" value="five1" id="five1">
                  <label for="five1" class="label">5%</label>
  
                  <input type="radio" class="radio" name="progress" value="twentyfive1" id="twentyfive1" checked>
                  <label for="twentyfive1" class="label">25%</label>
  
                  <input type="radio" class="radio" name="progress" value="fifty1" id="fifty1">
                  <label for="fifty1" class="label">50%</label>
  
                  <input type="radio" class="radio" name="progress" value="seventyfive1" id="seventyfive1">
                  <label for="seventyfive1" class="label">75%</label>
  
                  <input type="radio" class="radio" name="progress" value="onehundred1" id="onehundred1">
                  <label for="onehundred1" class="label">100%</label>
  
                  <div class="progress">
                    <div class="progress-bar"></div>
                  </div>
                  </div>
                  <!-- <div class="proInDi">
                    <img src="assets/images/user2.png">
                  </div> -->
                </div>
                <div class="multiImgUpload">
                  <div class="row">
                  <div class="col-md-12">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
  
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                                 <h4 class="panel-title">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <i class="fa fa-photo"></i> Multi Images Upload 
                            </a>
                          </h4>
  
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                <div class="panel-body">
                                  <div class="grid-x grid-padding-x">
                                    <div class="small-10 small-offset-1 medium-8 medium-offset-2 cell">
                                      <h4>Multiple Image File Upload with Preview</h4>
                                      <form action="upload_file.php" id="img-upload-form" method="post" enctype="multipart/form-data">
                                        <p class="multiImgBt">
                                          <label for="upload_imgs" class="button hollow">Select Your Images +</label>
                                          <input class="show-for-sr" type="file" id="upload_imgs" name="upload_imgs[]" multiple/>
                                        </p>
                                        <div class="quote-imgs-thumbs quote-imgs-thumbs--hidden" id="img_preview" aria-live="polite"></div>
                                        <p>
                                          <input class="button large expanded" type="submit" name="submit" value="Upload Images"/>
                                        </p>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                  </div>
                </div>
            <h4 class="proHead proHead2"><i class="fa fa-user"></i> About My Brother <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#proEditAbout"></i></h4>
            <div class="proInnerCont">
             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.</p>
            </div>
  
            <h4 class="proHead"><i class="fa fa-list"></i> Personal Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#personalDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Profile ID</b><span>: LR2703</span></li>
               <li><b>Full Name</b><span>: Rushikesh Jadhav</span></li>
               <li><b>Gender</b><span>: Male</span></li>
               <li><b>Date of Birth </b><span>: XX-XX-XXXX</span></li>
               <li><b>Age</b><span>: 27</span></li>
               <li><b>height</b><span>: 5 ft 8 (173 cm)</span></li>
               <li><b>Birth Time</b><span>: XX:XX</span></li>
               <li><b>Birth Place</b><span>: - - -</span></li>
               <li><b>Marital Status</b><span>: Never Married</span></li>
               <li><b>Mother Tounge</b><span>: Marathi</span></li>
               <li><b>Complexion</b><span>: - - -</span></li>
               <li><b>Blood Group</b><span>: B+</span></li>
               <li><b>Disability</b><span>: Normal</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-globe"></i> Religion Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#rligionDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Religion</b><span>: Hindu</span></li>
               <li><b>Caste</b><span>: Lingayat</span></li>
               <li><b>Sub Caste </b><span>: Pancham</span></li>
               <li><b>Gotra</b><span>: - - -</span></li>
               <li><b>Raas</b><span>: - - -</span></li>
               <li><b>Charan</b><span>: - - -</span></li>
               <li><b>Gana</b><span>: - - -</span></li>
               <li><b>Nakshtra</b><span>: - - -</span></li>
               <li><b>Nadi</b><span>: - - -</span></li>
               <li><b>Mangal Dosh</b><span>: No</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-drivers-license"></i> Contact Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#contactDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Contact Number</b><span>: Hindu</span></li>
               <li><b>Email ID</b><span>: XXXX@XXXXX.com</span></li>
               <li class="upgradeBtListing"><b><i class="fa fa-lock"></i> &nbsp; To unlock the contact detail</b><span>: <button><a href="#upgradeView">Upgrade Now</a></button></span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-map-marker"></i> Address Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#addressDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Address</b><span>: Vivekanand Nagar No-2, Nashik.</span></li>
               <li><b>Resident State</b><span>: Maharashtra</span></li>
               <li><b>Resident City / District</b><span>: Akola</span></li>
               <li><b>Resident Taluka</b><span>: - - -</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-drivers-license"></i> Professional Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#professionalDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Education</b><span>: B.Com</span></li>
               <li><b>Employee in</b><span>: Private</span></li>
               <li><b>Occupation</b><span>: Manager</span></li>
               <li><b>Job Country</b><span>: - - -</span></li>
               <li><b>Job City</b><span>: - - -</span></li>
               <li><b>Annual Income</b><span>: - - -</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-users"></i> Family Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#familyDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Father Name</b><span>: B.Com</span></li>
               <li><b>Father Occupation</b><span>: Private</span></li>
               <li><b>Father Contact No</b><span>: Manager</span></li>
               <li><b>Mother's Name</b><span>: - - -</span></li>
               <li><b>Mother's Occupation</b><span>: - - -</span></li>
               <li><b>Brother</b><span>: - - -</span></li>
               <li><b>Sister</b><span>: - - -</span></li>
               <li><b>Family Status</b><span>: - - -</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-cog"></i> Edit Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#editPreferences"></i></h4>
                <div class="proInnerCont">
                  <ul>
                     <li><b>Bride Age</b><span>: 27 to 35 Yrs</span></li>
                     <li><b>Height</b><span>: 5.8-6.6</span></li>
                     <li><b>Maritial Status</b><span>: Any</span></li>
                     <li><b>Disability</b><span>: Normal</span></li>
                  </ul>
                </div>
  
            <h4 class="proHead"><i class="fa fa-book"></i> Religious Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#religiousPreferencesEdit"></i></h4>
            <div class="proInnerCont">
              <ul>
               <li><b>Mother Tongue</b><span>: Any</span></li>
               <li><b>Physical Status</b><span>: Normal</span></li>
               <li><b>Religion</b><span>: Hindu</span></li>
               <li><b>Caste</b><span>: Lingayat</span></li>
               <li><b>Sub Caste</b><span>: - - -</span></li>
               <li><b>Nakshtra</b><span>: Any</span></li>
               <li><b>Mangal Dosh</b><span>: Yes</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-map-marker"></i> Location Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#locationPreferencesEdit"></i></h4>
            <div class="proInnerCont">
              <ul>
               <li><b>Country</b><span>: India</span></li>
               <li><b>State</b><span>: Maharashtra</span></li>
               <li><b>City</b><span>: Any</span></li>
               <li><b>Job Country</b><span>: Any</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-graduation-cap"></i> Professional Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#professionalPreferencEdit"></i></h4>
            <div class="proInnerCont">
              <ul>
               <li><b>Education</b><span>: Any</span></li>
               <li><b>Occupation</b><span>: Maharashtra</span></li>
               <li><b>Employee In</b><span>: Any</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-user"></i> About Partner <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#aboutPartnerEdit"></i></h4>
            <div class="proInnerCont">
              <textarea class="textAria">She should be fair</textarea>
            </div>
  
  
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
             </div>
           </div>
         </div>
         <div id="editPreference">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Edit Preference</h2> -->
           <div class="dashMainCont">
             <div class="dashMainInner">
               <div class="proDetailsDiv">
                <div class="proDi">
                  <div class="overDiv overDivBrows">
                <div class="contain animated bounce">
                  <form id="form1" runat="server">
                      <div id='img_contain'><img id="blah" align='middle' src="assets/images/default-avatar.jpg" alt="your image" title=''/></div> 
                      <div class="input-group"> 
                      <div class="custom-file">+
                      <input type="file" id="inputGroupFile01" class="imgInp custom-file-input" aria-describedby="inputGroupFileAddon01">
                      </div>
                      </div>
                  </form>
                </div>
                <p class="proname">Prem Gaikwad</p>
                  </div>
                  <!-- <div class="proInDi">
                    <img src="assets/images/user2.png">
                  </div> -->
  
                  <div class="comlpetedProDiv">
                    <input type="radio" class="radio" name="progress" value="five" id="five">
                  <label for="five" class="label">5%</label>
  
                  <input type="radio" class="radio" name="progress" value="twentyfive" id="twentyfive" checked>
                  <label for="twentyfive" class="label">25%</label>
  
                  <input type="radio" class="radio" name="progress" value="fifty" id="fifty">
                  <label for="fifty" class="label">50%</label>
  
                  <input type="radio" class="radio" name="progress" value="seventyfive" id="seventyfive">
                  <label for="seventyfive" class="label">75%</label>
  
                  <input type="radio" class="radio" name="progress" value="onehundred" id="onehundred">
                  <label for="onehundred" class="label">100%</label>
  
                  <div class="progress">
                    <div class="progress-bar"></div>
                  </div>
                  </div>
  
                </div>
            <h4 class="proHead proHead2"><i class="fa fa-user"></i> About My Brother <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#proEditAbout"></i></h4>
            <div class="proInnerCont">
             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.</p>
            </div>
  
            <h4 class="proHead"><i class="fa fa-list"></i> Personal Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#personalDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Profile ID</b><span>: LR2703</span></li>
               <li><b>Full Name</b><span>: Rushikesh Jadhav</span></li>
               <li><b>Gender</b><span>: Male</span></li>
               <li><b>Date of Birth </b><span>: XX-XX-XXXX</span></li>
               <li><b>Age</b><span>: 27</span></li>
               <li><b>height</b><span>: 5 ft 8 (173 cm)</span></li>
               <li><b>Birth Time</b><span>: XX:XX</span></li>
               <li><b>Birth Place</b><span>: - - -</span></li>
               <li><b>Marital Status</b><span>: Never Married</span></li>
               <li><b>Mother Tounge</b><span>: Marathi</span></li>
               <li><b>Complexion</b><span>: - - -</span></li>
               <li><b>Blood Group</b><span>: B+</span></li>
               <li><b>Disability</b><span>: Normal</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-globe"></i> Religion Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#rligionDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Religion</b><span>: Hindu</span></li>
               <li><b>Caste</b><span>: Lingayat</span></li>
               <li><b>Sub Caste </b><span>: Pancham</span></li>
               <li><b>Gotra</b><span>: - - -</span></li>
               <li><b>Raas</b><span>: - - -</span></li>
               <li><b>Charan</b><span>: - - -</span></li>
               <li><b>Gana</b><span>: - - -</span></li>
               <li><b>Nakshtra</b><span>: - - -</span></li>
               <li><b>Nadi</b><span>: - - -</span></li>
               <li><b>Mangal Dosh</b><span>: No</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-drivers-license"></i> Contact Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#contactDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Contact Number</b><span>: Hindu</span></li>
               <li><b>Email ID</b><span>: XXXX@XXXXX.com</span></li>
               <li class="upgradeBtListing"><b><i class="fa fa-lock"></i> &nbsp; To unlock the contact detail</b><span>: <button><a href="#upgradeView">Upgrade Now</a></button></span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-map-marker"></i> Address Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#addressDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Address</b><span>: Vivekanand Nagar No-2, Nashik.</span></li>
               <li><b>Resident State</b><span>: Maharashtra</span></li>
               <li><b>Resident City / District</b><span>: Akola</span></li>
               <li><b>Resident Taluka</b><span>: - - -</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-drivers-license"></i> Professional Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#professionalDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Education</b><span>: B.Com</span></li>
               <li><b>Employee in</b><span>: Private</span></li>
               <li><b>Occupation</b><span>: Manager</span></li>
               <li><b>Job Country</b><span>: - - -</span></li>
               <li><b>Job City</b><span>: - - -</span></li>
               <li><b>Annual Income</b><span>: - - -</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-users"></i> Family Details <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#familyDetailsEdit"></i></h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Father Name</b><span>: B.Com</span></li>
               <li><b>Father Occupation</b><span>: Private</span></li>
               <li><b>Father Contact No</b><span>: Manager</span></li>
               <li><b>Mother's Name</b><span>: - - -</span></li>
               <li><b>Mother's Occupation</b><span>: - - -</span></li>
               <li><b>Brother</b><span>: - - -</span></li>
               <li><b>Sister</b><span>: - - -</span></li>
               <li><b>Family Status</b><span>: - - -</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-cog"></i> Edit Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#editPreferences"></i></h4>
                <div class="proInnerCont">
                  <ul>
                     <li><b>Bride Age</b><span>: 27 to 35 Yrs</span></li>
                     <li><b>Height</b><span>: 5.8-6.6</span></li>
                     <li><b>Maritial Status</b><span>: Any</span></li>
                     <li><b>Disability</b><span>: Normal</span></li>
                  </ul>
                </div>
  
            <h4 class="proHead"><i class="fa fa-book"></i> Religious Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#religiousPreferencesEdit"></i></h4>
            <div class="proInnerCont">
              <ul>
               <li><b>Mother Tongue</b><span>: Any</span></li>
               <li><b>Physical Status</b><span>: Normal</span></li>
               <li><b>Religion</b><span>: Hindu</span></li>
               <li><b>Caste</b><span>: Lingayat</span></li>
               <li><b>Sub Caste</b><span>: - - -</span></li>
               <li><b>Nakshtra</b><span>: Any</span></li>
               <li><b>Mangal Dosh</b><span>: Yes</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-map-marker"></i> Location Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#locationPreferencesEdit"></i></h4>
            <div class="proInnerCont">
              <ul>
               <li><b>Country</b><span>: India</span></li>
               <li><b>State</b><span>: Maharashtra</span></li>
               <li><b>City</b><span>: Any</span></li>
               <li><b>Job Country</b><span>: Any</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-graduation-cap"></i> Professional Preferences <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#professionalPreferencEdit"></i></h4>
            <div class="proInnerCont">
              <ul>
               <li><b>Education</b><span>: Any</span></li>
               <li><b>Occupation</b><span>: Maharashtra</span></li>
               <li><b>Employee In</b><span>: Any</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-user"></i> About Partner <i class="fa fa-pencil proEditIco" data-toggle="modal" data-target="#aboutPartnerEdit"></i></h4>
            <div class="proInnerCont">
              <textarea class="textAria">She should be fair</textarea>
            </div>
  
  
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
             </div>
           </div>
         </div>
         <div id="photoGallery">
           <div class="dashMainCont">
             <div class="dashMainInner">
              <div class="row">
                <div class="col-md-3">
                  <div class="column">
                    <i class="fa fa-close phoGalleDele"></i>
                    <img src="assets/images/pho1.jpg" style="width:100%" onclick="openModal();currentSlide(1)" class="hover-shadow cursor">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="column">
                    <i class="fa fa-close phoGalleDele"></i>
                    <img src="assets/images/pho2.jpg" style="width:100%" onclick="openModal();currentSlide(2)" class="hover-shadow cursor">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="column">
                    <i class="fa fa-close phoGalleDele"></i>
                    <img src="assets/images/pho1.jpg" style="width:100%" onclick="openModal();currentSlide(3)" class="hover-shadow cursor">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="column">
                    <i class="fa fa-close phoGalleDele"></i>
                    <img src="assets/images/pho2.jpg" style="width:100%" onclick="openModal();currentSlide(4)" class="hover-shadow cursor">
                  </div>
                </div>
          </div>
  
  
             </div>
           </div>
         </div>
         <div id="rateUs">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Rate Us</h2> -->
         </div>
         <div id="settings">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Settings</h2> -->
           <div class="dashMainCont">
             <div class="dashMainInner">
               <div class="row">
             <div class="col-md-6">
               <div class="settingsDiv settingsBg1" data-toggle="modal" data-target="#changePassword">
                <div><i class="fa fa-key"></i></div>
                 <h1>Change Password</h1>
               </div>
             </div>
             <div class="col-md-6">
               <div class="settingsDiv settingsBg2" data-toggle="modal" data-target="#deleteAc">
                <div><i class="fa fa-trash"></i></div>
                 <h1>Delete Account</h1>
               </div>
             </div>
             <div class="col-md-6">
               <div class="settingsDiv settingsBg3" data-toggle="modal" data-target="#TermsAndCondi">
                <div><i class="fa fa-vcard"></i></div>
                 <h1>Terms & Conditions</h1>
               </div>
             </div>
             <div class="col-md-6">
               <div class="settingsDiv settingsBg4" data-toggle="modal" data-target="#privacyPolicy">
                <div><i class="fa fa-suitcase"></i></div>
                 <h1>Privacy Policy</h1>
               </div>
             </div>
           </div>
             </div>
           </div>
         </div>
  
         <div id="changPass">
           <div class="dashMainCont">
            <h5>Change Your Password</h5>
             <div class="dashMainInner">
               <div class="changePasMain">
                 <div class="row">
                   <div class="col-md-6">
                     <input type="Password" placeholder="Old Passsword" class="inputFil" name="">
                   </div>
                   <div class="col-md-6">
                     <input type="Password" placeholder="New Passsword" class="inputFil" name="">
                   </div>
                   <div class="col-md-6">
                     <input type="Password" placeholder="Confirm Passsword" class="inputFil" name="">
                   </div>
                   <!-- <div class="col-md-6">
                     <input type="Password" placeholder="Hint" class="inputFil" name="">
                   </div> -->
                   <div class="col-md-12">
                    <hr class="deviLine">
                    <button class="passBt">Change Password</button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
  
         <div id="termsAcond">
           <div class="dashMainCont">
            <h5>Terms & Conditions</h5>
             <div class="dashMainInner">
               <div>
                 <div class="row">  
            <div class="col-md-12">
              <b class="termsAndNo">1. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">2. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">3. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">4. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
            </div>
          </div>
               </div>
             </div>
           </div>
         </div>
  
         <div id="privacyPoli">
           <div class="dashMainCont">
            <h5>Privacy Policy</h5>
             <div class="dashMainInner">
               <div>
                 <div class="row">  
            <div class="col-md-12">
              <b class="termsAndNo">1. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">2. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">3. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">4. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
            </div>
          </div>
               </div>
             </div>
           </div>
         </div>
  
         <div id="help">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Help</h2> -->
           <div class="dashMainCont">
             <div class="dashMainInner">
               <div class="row">
                 <div class="col-md-4">
                   <div class="helpDiv help1">
                     <h1>Our Time</h1>
                     <p>Timing 10:00 AM To 07:00 PM</p>
                     <p>For any other help and queries</p>
                   </div>
                 </div>
                 <div class="col-md-4">
                   <div class="helpDiv help2">
                     <h1>Contact 1</h1>
                     <p>9890955572</p>
                     <p>help@lingayatreshimgathi.com</p>
                   </div>
                 </div>
                 <div class="col-md-4">
                   <div class="helpDiv help3">
                     <h1>Contact 2</h1>
                     <p>9284086178</p>
                     <p>Fraudreport@lingayatreshimgathi.com</p>
                   </div>
                 </div>
                 <div class="col-md-12">
                   <h3>Fraud Assistance</h3>
                   <p>To report fraud, contact our fraud assistance team immediately.
                   Your information will be kipt confidential</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div id="logout">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Logout</h2> -->
         </div>
  
  <!-- SubMenu Div Here -->
        <div id="myMatche" class="myMatchesStyle" >
          <div class="search-reault" 
  infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()">
          <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
           <div class="dashMainCont" *ngIf="!logspinner">
             <div class="dashMainInner">
                <div class="matchesInner">
                  <div class="row">
  
                    
                    
                <div *ngFor="let responceData of mymatchesresult"> 
                    <div class="col-md-4">
                      <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">{{responceData.MemberCNtMultiplePhotos}}</div>
                            <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                            <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                              <img [src]='photopath+responceData.MemberPhotoPath'>
                              <div *ngIf="responceData.MemberIsPhotoUpload === '0'">
                              <button class="requestBtUp">Request Photo</button>
                            </div>
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="responceData.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="responceData.MemberSentInterestFlag == '0'"></i></span>
                              <span data-tooltip="Shortlisted">
                                <i class="fa fa-star starIc shortLis" *ngIf="responceData.MemberShortlistFlag !== '0'"></i>
                                <i class="fa fa-star starIc" *ngIf="responceData.MemberShortlistFlag == '0'"></i>
                              </span>
                              </div>
  
                              <div class="detailsOuter">
                              <h4 class="callDetaillsName">{{responceData.MemberName}} ({{responceData.MemberProfileID}})</h4>
                              <p>{{responceData.MemberEducation}}, Job-City - {{responceData.MemberJobCity}}</p>
                              <p>{{responceData.MemberAge}} yrs, {{responceData.MemberHeight}} | {{responceData.MemberMartialStatus}}</p>
                              <p>{{responceData.MemberCaste}}, {{responceData.MemberNativePlace}} </p>
                            </div>
                              
                              <button type="button" class="proDetailsBt" (click)="memberdetailspost(responceData)">
                                View Profile
                              </button>
                          </div>
                      </div>
                  </div>
                </div>
    </div>
  
                  
  
  
  
                  </div>
                </div>
             </div>
           </div>
        </div>
  
        <div id="newMatche" class="myMatchesStyle">
         
          <div class="samplecover-spin" id="samplecover-spin" *ngIf="newmatchesspinner"></div>
          <div class="dashMainCont" *ngIf="!newmatchesspinner">
  
           <div class="dashMainCont">
             <div class="dashMainInner">
              <div class="newMatchesMain">
                <div class="row">
                  <div class="col-md-8">
                    <!-- <a href="#proDetails" class="allPro">View Profile</a> -->
                    <div class="row">
                      <div class="col-md-12">
                        <h5>225 New matches <span class="shoNewMatc">125 New</span> <a href="#proDetails" class="newMViewPro">View All Profile</a></h5>
                      </div>
                      <!-- <div *ngFor="let newmatch of newMatchesdash"></div> -->
  
  
                  <div class="col-md-3 col-sm-6 col-xs-6" *ngFor="let newmatch of newMatchesdash">
                    <div class="newMatcInner">
                      <div class="newMaImg"><img [src]='photopath+newmatch.MemberPhotoPath'></div>
                      <P>{{newmatch.MemberName}}</P>
                      <p>({{newmatch.MemberProfileID}})</p>
                      <p>{{newmatch.MemberAge}} yrs, {{newmatch.MemberHeight}} ft</p>
                      <a href="javascript:void(0);" (click)="memberdetailspost(newmatch)">View Profile</a>
                    </div>
                  </div>
                  
                  
  
                  <!-- <div class="viewAllProDiv newMatchSpace">
                    <a href="#proDetails">View Profile</a>
                  </div> -->
                  
                    <div class="col-md-12">
                      <div class="discouPachMain">
                        <h5>Discounts as "Classy" as you are..!</h5>
                        <p>Get a special discount of Rs.2800 on 3 Month Classic Premium pack</p>
                        <button class="upgrBt">Upgrade Now</button>
                      </div>
                    </div>
                
                <div class="col-md-12">
                  <div class="disMaMain">
                    <h5>Discover Matches </h5>
                    <div class="panel with-nav-tabs panel-default">
                  <div class="panel-heading">
                          <ul class="nav nav-tabs">
                              <li class="active"><a href="#tab1default" data-toggle="tab">City (860)</a></li>
                              <li><a href="#tab2default" data-toggle="tab">Professions (999+)</a></li>
                              <li><a href="#tab3default" data-toggle="tab">Education (999+)</a></li>
                              <li><a href="#tab4default" data-toggle="tab">View All Profile</a></li>
                          </ul>
                  </div>
                  <div class="panel-body">
                      <div class="tab-content">
                          <div class="tab-pane fade in active" id="tab1default">
                            <div class="row">
                              <div class="col-md-4 col-xs-6">
                                <div class="newMatcInner">
                                  <div class="newMaImg"><img src="assets/images/matches2.jpg"></div>
                                  <P>Shubha Gaikwad</P>
                                  <p>R1789836</p>
                                  <p>26 yrs, 5.5 ft</p>
                                  <p>96 Kuli Maratha, Mumbai</p>
                                  <a href="#proDetails">View Profile</a>
                                </div>
                              </div>
  
                            </div>
                          </div>
                          <div class="tab-pane fade" id="tab2default">
                              <div class="row">
                                <div class="col-md-4 col-xs-6">
                                <div class="newMatcInner">
                                  <div class="newMaImg"><img src="assets/images/matches2.jpg"></div>
                                  <P>Shubha Gaikwad</P>
                                  <p>R1789836</p>
                                  <p>26 yrs, 5.5 ft</p>
                                  <p>96 Kuli Maratha, Mumbai</p>
                                  <a href="#proDetails">View Profile</a>
                                </div>
                              </div>
                              </div>
                          </div>
                          <div class="tab-pane fade" id="tab3default">
                              <div class="row">
                                  <div class="col-md-4 col-xs-6">
                                <div class="newMatcInner">
                                  <div class="newMaImg"><img src="assets/images/matches2.jpg"></div>
                                  <P>Shubha Gaikwad</P>
                                  <p>R1789836</p>
                                  <p>26 yrs, 5.5 ft</p>
                                  <p>96 Kuli Maratha, Mumbai</p>
                                  <a href="#proDetails">View Profile</a>
                                </div>
                              </div>
                              </div>
                          </div>
                          <div class="tab-pane fade" id="tab4default">
                              <div class="row">
                                  <div class="col-md-4 col-xs-6">
                                  <div class="newMatcInner">
                                    <div class="newMaImg"><img src="assets/images/matches2.jpg"></div>
                                    <P>Shubha Gaikwad</P>
                                    <p>R1789836</p>
                                    <p>26 yrs, 5.5 ft</p>
                                    <p>96 Kuli Maratha, Mumbai</p>
                                    <a href="#proDetails">View Profile</a>
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  </div>
                </div>
                  <!-- <div class="viewAllProDiv"><a href="#proDetails">View Profile</a></div> -->
                </div>
                </div>
  
                <div class="col-md-4">
                    <div class="col-md-12" style="padding: 0 5px;">
                        <h5 style="opacity: 0; visibility: hidden; display: none;">Counter</h5>
                    </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 sideSpace">
                    <div class="serViewDiv">
                      <span class="serNo serCou">01</span>
                      <span class="serNo">Response Received</span>                    
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 sideSpace">
                    <div class="serViewDiv">
                      <span class="serNo serCou">08</span>
                      <span class="serNo">Accepted Request</span>                    
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 sideSpace">
                    <div class="serViewDiv">
                      <span class="serNo serCou">04</span>
                      <span class="serNo">Shortlisted Me</span>                    
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 sideSpace">
                    <div class="serViewDiv">
                      <span class="serNo serCou">05</span>
                      <span class="serNo">Viewed My Profile</span>                    
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-12">
                      <div class="memberVieweedMain">
                        <h5>3 Members Viewed Your Profile</h5>
                            <div class="newMatcInner">
                              <div class="newMaImg"><img src="assets/images/matches2.jpg"></div>
                              <div class="proCon">
                                <P>Shubha Gaikwad</P>
                                <p>R1789836</p>
                                <p>26 yrs, 5.5 ft</p>
                                <p>96 Kuli Maratha, Mumbai</p>
                                <a href="#proDetails">View Profile</a>
                              </div>
                            </div>
  
                      </div>
                    </div>
                </div>
                </div>
  
                </div>
              </div>
             </div>
           </div>
           </div>
        </div>
  
        <div id="nearMe" class="myMatchesStyle">
  
          <div class="samplecover-spin" id="samplecover-spin" *ngIf="lognearmespinner"></div>
          <div class="dashMainCont" *ngIf="!lognearmespinner">
  
            <div class="dashMainInner">
              <div class="matchesInner">
                <button class="nearMeExpand">Matches from <span class="caret"></span></button>
                <div style="clear: both;"></div>
                <div class="nearCityDiv">
                  <div class="row">
                    <div class="col-md-4">
                  <ul>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="nashik">
                        <label for="nashik">Nashik</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="aurangabad">
                        <label for="aurangabad">Aurangabad</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="malegoan">
                        <label for="malegoan">Malegoan</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="chandwad">
                        <label for="chandwad">Chandwad</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="shirdi">
                        <label for="shirdi">Shirdi</label>
                      </div>
                    </li>
                  </ul>
                  </div>
                  <div class="col-md-4">
                  <ul>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="nashik1">
                        <label for="nashik1">Nashik</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="aurangabad1">
                        <label for="aurangabad1">Aurangabad</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="malegoan1">
                        <label for="malegoan1">Malegoan</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="chandwad1">
                        <label for="chandwad1">Chandwad</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="shirdi1">
                        <label for="shirdi1">Shirdi</label>
                      </div>
                    </li>
                  </ul>
                  </div>
                  <div class="col-md-4">
                  <ul>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="nashik2">
                        <label for="nashik2">Nashik</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="aurangabad2">
                        <label for="aurangabad2">Aurangabad</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="malegoan2">
                        <label for="malegoan2">Malegoan</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="chandwad2">
                        <label for="chandwad2">Chandwad</label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <input type="checkbox" id="shirdi2">
                        <label for="shirdi2">Shirdi</label>
                      </div>
                    </li>
                  </ul>
                  </div>
                  </div>
                  <button class="applyNearBt">Apply</button>
                </div>
                <div class="row">
  
  
   <div *ngFor="let responceNearmeData of nearmeval"> 
                 <div class="col-md-4">
  
  
  
                   <div class="matchesDiv">
                     <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                     <div class="matchesPic">
                       <div class="coun">{{responceNearmeData.MemberCNtMultiplePhotos}}</div>
                       <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                       <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                         <img [src]='photopath+responceNearmeData.MemberPhotoPath'>
                         <div *ngIf="responceNearmeData.MemberIsPhotoUpload === '0'">
                         <button class="requestBtUp">Request Photo</button>
                       </div>
                         <i class="fa fa-camera phoIco"></i>
                     </div>
                     <div class="callDetais">
                         <div class="floatNeed">
                         <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                         <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="responceNearmeData.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="responceNearmeData.MemberSentInterestFlag == '0'"></i></span>
                         <span data-tooltip="Shortlisted">
                           <i class="fa fa-star starIc shortLis" *ngIf="responceNearmeData.MemberShortlistFlag !== '0'"></i>
                           <i class="fa fa-star starIc" *ngIf="responceNearmeData.MemberShortlistFlag == '0'"></i>
                         </span>
                         </div>
  
                         <div class="detailsOuter">
                         <h4 class="callDetaillsName">{{responceNearmeData.MemberName}} ({{responceNearmeData.MemberProfileID}})</h4>
                         <p>{{responceNearmeData.MemberEducation}}, Job-City - {{responceNearmeData.MemberJobCity}}</p>
                         <p>{{responceNearmeData.MemberAge}} yrs, {{responceNearmeData.MemberHeight}} | {{responceNearmeData.MemberMartialStatus}}</p>
                         <p>{{responceNearmeData.MemberCaste}}, {{responceNearmeData.MemberNativePlace}} </p>
                       </div>
                         
                         <button type="button" class="proDetailsBt" (click)="memberdetailspost(responceNearmeData)">
                           View Profile
                         </button>
                     </div>
                 </div>
  
  
  
  
  
  
                  
               </div>
  </div>
  
  
  
                </div>
              </div>
           </div>
          
          </div>
        </div>
  
        <div id="shortlisted" class="myMatchesStyle">
        
          <div class="samplecover-spin" id="samplecover-spin" *ngIf="logshortlistedspinner"></div>
          <div class="dashMainCont" *ngIf="!logshortlistedspinner">
  
            
          <div class="dashMainCont">
            <div class="dashMainInner">
               <div class="matchesInner">
                 <button class="nearMeExpand">Matches from <span class="caret"></span></button>
                 <div style="clear: both;"></div>
                 <div class="nearCityDiv">
                   <div class="row">
                     <div class="col-md-4">
                   <ul>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="nashik">
                         <label for="nashik">Nashik</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="aurangabad">
                         <label for="aurangabad">Aurangabad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="malegoan">
                         <label for="malegoan">Malegoan</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="chandwad">
                         <label for="chandwad">Chandwad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="shirdi">
                         <label for="shirdi">Shirdi</label>
                       </div>
                     </li>
                   </ul>
                   </div>
                   <div class="col-md-4">
                   <ul>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="nashik1">
                         <label for="nashik1">Nashik</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="aurangabad1">
                         <label for="aurangabad1">Aurangabad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="malegoan1">
                         <label for="malegoan1">Malegoan</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="chandwad1">
                         <label for="chandwad1">Chandwad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="shirdi1">
                         <label for="shirdi1">Shirdi</label>
                       </div>
                     </li>
                   </ul>
                   </div>
                   <div class="col-md-4">
                   <ul>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="nashik2">
                         <label for="nashik2">Nashik</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="aurangabad2">
                         <label for="aurangabad2">Aurangabad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="malegoan2">
                         <label for="malegoan2">Malegoan</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="chandwad2">
                         <label for="chandwad2">Chandwad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="shirdi2">
                         <label for="shirdi2">Shirdi</label>
                       </div>
                     </li>
                   </ul>
                   </div>
                   </div>
                   <button class="applyNearBt">Apply</button>
                 </div>
                 <div class="row">
                  <div *ngFor="let ShortlistedData of shortlistedval"> 
  
                    
                    <div class="col-md-4">
  
  
  
                      <div class="matchesDiv">
                        <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                        <div class="matchesPic">
                          <div class="coun">{{ShortlistedData.MemberCNtMultiplePhotos}}</div>
                          <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                          <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                            <img [src]='photopath+ShortlistedData.MemberPhotoPath'>
                            <div *ngIf="ShortlistedData.MemberIsPhotoUpload === '0'">
                            <button class="requestBtUp">Request Photo</button>
                          </div>
                            <i class="fa fa-camera phoIco"></i>
                        </div>
                        <div class="callDetais">
                            <div class="floatNeed">
                            <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                            <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="ShortlistedData.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="ShortlistedData.MemberSentInterestFlag == '0'"></i></span>
                            <span data-tooltip="Shortlisted">
                              <i class="fa fa-star starIc shortLis" *ngIf="ShortlistedData.MemberShortlistFlag !== '0'"></i>
                              <i class="fa fa-star starIc" *ngIf="ShortlistedData.MemberShortlistFlag == '0'"></i>
                            </span>
                            </div>
     
                            <div class="detailsOuter">
                            <h4 class="callDetaillsName">{{ShortlistedData.MemberName}} ({{ShortlistedData.MemberProfileID}})</h4>
                            <p>{{ShortlistedData.MemberEducation}}, Job-City - {{ShortlistedData.MemberJobCity}}</p>
                            <p>{{ShortlistedData.MemberAge}} yrs, {{ShortlistedData.MemberHeight}} | {{ShortlistedData.MemberMartialStatus}}</p>
                            <p>{{ShortlistedData.MemberCaste}}, {{ShortlistedData.MemberNativePlace}} </p>
                          </div>
                            
                            <button type="button" class="proDetailsBt" (click)="memberdetailspost(ShortlistedData)">
                              View Profile
                            </button>
                        </div>
                    </div>
     
     
     
     
     
     
                     
                  </div>
  
  
                 </div>
               </div>
            </div>
          </div>
          </div>
  
          </div>
    
         </div>
  
  
  
  
  
  
         <div id="viewedmyProfile" class="myMatchesStyle">
  
          <div class="samplecover-spin" id="samplecover-spin" *ngIf="viewedmyprofilespinner"></div>
          <div class="dashMainCont" *ngIf="!viewedmyprofilespinner">
  
            <div class="dashMainCont">
              <div class="dashMainInner">
                 <div class="matchesInner">
                   <button class="nearMeExpand">Matches from <span class="caret"></span></button>
                   <div style="clear: both;"></div>
                   <div class="nearCityDiv">
                     <div class="row">
                       <div class="col-md-4">
                     <ul>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="nashik">
                           <label for="nashik">Nashik</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="aurangabad">
                           <label for="aurangabad">Aurangabad</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="malegoan">
                           <label for="malegoan">Malegoan</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="chandwad">
                           <label for="chandwad">Chandwad</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="shirdi">
                           <label for="shirdi">Shirdi</label>
                         </div>
                       </li>
                     </ul>
                     </div>
                     <div class="col-md-4">
                     <ul>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="nashik1">
                           <label for="nashik1">Nashik</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="aurangabad1">
                           <label for="aurangabad1">Aurangabad</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="malegoan1">
                           <label for="malegoan1">Malegoan</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="chandwad1">
                           <label for="chandwad1">Chandwad</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="shirdi1">
                           <label for="shirdi1">Shirdi</label>
                         </div>
                       </li>
                     </ul>
                     </div>
                     <div class="col-md-4">
                     <ul>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="nashik2">
                           <label for="nashik2">Nashik</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="aurangabad2">
                           <label for="aurangabad2">Aurangabad</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="malegoan2">
                           <label for="malegoan2">Malegoan</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="chandwad2">
                           <label for="chandwad2">Chandwad</label>
                         </div>
                       </li>
                       <li>
                         <div class="form-group">
                           <input type="checkbox" id="shirdi2">
                           <label for="shirdi2">Shirdi</label>
                         </div>
                       </li>
                     </ul>
                     </div>
                     </div>
                     <button class="applyNearBt">Apply</button>
                   </div>
                   <div class="row">
                    <div *ngFor="let viewedmyProfiledata of myprofilevar"> 
    
                      
                      <div class="col-md-4">
    
    
    
                        <div class="matchesDiv">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">{{viewedmyProfiledata.MemberCNtMultiplePhotos}}</div>
                            <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                            <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                              <img [src]='photopath+viewedmyProfiledata.MemberPhotoPath'>
                              <div *ngIf="viewedmyProfiledata.MemberIsPhotoUpload === '0'">
                              <button class="requestBtUp">Request Photo</button>
                            </div>
                              <i class="fa fa-camera phoIco"></i>
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="viewedmyProfiledata.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="viewedmyProfiledata.MemberSentInterestFlag == '0'"></i></span>
                              <span data-tooltip="Shortlisted">
                                <i class="fa fa-star starIc shortLis" *ngIf="viewedmyProfiledata.MemberShortlistFlag !== '0'"></i>
                                <i class="fa fa-star starIc" *ngIf="viewedmyProfiledata.MemberShortlistFlag == '0'"></i>
                              </span>
                              </div>
       
                              <div class="detailsOuter">
                              <h4 class="callDetaillsName">{{viewedmyProfiledata.MemberName}} ({{viewedmyProfiledata.MemberProfileID}})</h4>
                              <p>{{viewedmyProfiledata.MemberEducation}}, Job-City - {{viewedmyProfiledata.MemberJobCity}}</p>
                              <p>{{viewedmyProfiledata.MemberAge}} yrs, {{viewedmyProfiledata.MemberHeight}} | {{viewedmyProfiledata.MemberMartialStatus}}</p>
                              <p>{{viewedmyProfiledata.MemberCaste}}, {{viewedmyProfiledata.MemberNativePlace}} </p>
                            </div>
                              
                              <button type="button" class="proDetailsBt" (click)="memberdetailspost(viewedmyProfiledata)">
                                View Profile
                              </button>
                          </div>
                      </div>
       
       
       
       
       
       
                       
                    </div>
    
    
                   </div>
                 </div>
              </div>
            </div>
            </div>
          
         </div>
         </div>
  
  
         <div id="shortlistedMe" class="myMatchesStyle">
  
          <div class="samplecover-spin" id="samplecover-spin" *ngIf="logshortlistmespinner"></div>
          <div class="dashMainCont" *ngIf="!logshortlistmespinner">
  
            <div class="dashMainInner">
               <div class="matchesInner">
                 <button class="nearMeExpand">Matches from <span class="caret"></span></button>
                 <div style="clear: both;"></div>
                 <div class="nearCityDiv">
                   <div class="row">
                     <div class="col-md-4">
                   <ul>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="nashik">
                         <label for="nashik">Nashik</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="aurangabad">
                         <label for="aurangabad">Aurangabad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="malegoan">
                         <label for="malegoan">Malegoan</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="chandwad">
                         <label for="chandwad">Chandwad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="shirdi">
                         <label for="shirdi">Shirdi</label>
                       </div>
                     </li>
                   </ul>
                   </div>
                   <div class="col-md-4">
                   <ul>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="nashik1">
                         <label for="nashik1">Nashik</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="aurangabad1">
                         <label for="aurangabad1">Aurangabad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="malegoan1">
                         <label for="malegoan1">Malegoan</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="chandwad1">
                         <label for="chandwad1">Chandwad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="shirdi1">
                         <label for="shirdi1">Shirdi</label>
                       </div>
                     </li>
                   </ul>
                   </div>
                   <div class="col-md-4">
                   <ul>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="nashik2">
                         <label for="nashik2">Nashik</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="aurangabad2">
                         <label for="aurangabad2">Aurangabad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="malegoan2">
                         <label for="malegoan2">Malegoan</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="chandwad2">
                         <label for="chandwad2">Chandwad</label>
                       </div>
                     </li>
                     <li>
                       <div class="form-group">
                         <input type="checkbox" id="shirdi2">
                         <label for="shirdi2">Shirdi</label>
                       </div>
                     </li>
                   </ul>
                   </div>
                   </div>
                   <button class="applyNearBt">Apply</button>
                 </div>
                 <div class="row">
  
  
    <div *ngFor="let responceShortmeData of shortlistmedash"> 
                  <div class="col-md-4">
  
  
  
                    <div class="matchesDiv">
                      <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                      <div class="matchesPic">
                        <div class="coun">{{responceShortmeData.MemberCNtMultiplePhotos}}</div>
                        <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                        <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                          <img [src]='photopath+responceShortmeData.MemberPhotoPath'>
                          <div *ngIf="responceShortmeData.MemberIsPhotoUpload === '0'">
                          <button class="requestBtUp">Request Photo</button>
                        </div>
                          <i class="fa fa-camera phoIco"></i>
                      </div>
                      <div class="callDetais">
                          <div class="floatNeed">
                          <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                          <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="responceShortmeData.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="responceShortmeData.MemberSentInterestFlag == '0'"></i></span>
                          <span data-tooltip="Shortlisted">
                            <i class="fa fa-star starIc shortLis" *ngIf="responceShortmeData.MemberShortlistFlag !== '0'"></i>
                            <i class="fa fa-star starIc" *ngIf="responceShortmeData.MemberShortlistFlag == '0'"></i>
                          </span>
                          </div>
  
                          <div class="detailsOuter">
                          <h4 class="callDetaillsName">{{responceShortmeData.MemberName}} ({{responceShortmeData.MemberProfileID}})</h4>
                          <p>{{responceShortmeData.MemberEducation}}, Job-City - {{responceShortmeData.MemberJobCity}}</p>
                          <p>{{responceShortmeData.MemberAge}} yrs, {{responceShortmeData.MemberHeight}} | {{responceShortmeData.MemberMartialStatus}}</p>
                          <p>{{responceShortmeData.MemberCaste}}, {{responceShortmeData.MemberNativePlace}} </p>
                        </div>
                          
                          <button type="button" class="proDetailsBt" (click)="memberdetailspost(responceShortmeData)">
                            View Profile
                          </button>
                      </div>
                  </div>
  
  
  
  
  
  
                   
                </div>
  </div>
  
  
  
                 </div>
               </div>
            </div>
          </div>
         </div>
  
  
  
  
  
         
  
         <div id="mailsMain">
          <div class="col-md-12">
                  <div class="inboHove">
                    <span>INBOX <span class="caret"></span></span>
                  <div class="dropMenu">
                  <ul>
                    <li role="presentation"><a href="#mailsMain">Index</a></li>
                    <li role="presentation"><a>Send</a></li>
                  </ul>
                </div>
                </div>
                </div>
                <button class="deleteTopBt" style="visibility: hidden;
                opacity: 0;">Declined</button> <button class="accepTopBt" style="visibility: hidden;
                opacity: 0;">Accepted</button>
           <div class="dashMainCont">
             <div class="dashMainInner">
                <div class="row">
                  <div class="col-md-6">
                     <div class="mailDiv">
                      <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                       <div class="mailContDiv">
                         <h4 class="mailHead">Interest Request</h4>
                         <p>Swati Sonawane</p>
                         <p>(LR2234) has liked your profile and has send an interest to you.</p>
                         <button class="acceptBt">Accept</button>
                         <button class="deleteBt">Declined</button>
                       </div>
                     </div>
                   </div>
                   <div class="col-md-6">
                     <div class="mailDiv">
                      <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                       <div class="mailContDiv">
                         <h4 class="mailHead">Interest Request</h4>
                         <p>Swati Sonawane</p>
                         <p>(LR2234) has liked your profile and has send an interest to you.</p>
                         <button class="acceptBt">Accept</button>
                         <button class="deleteBt">Declined</button>
                       </div>
                     </div>
                   </div>
                </div>
             </div>
           </div>
         </div>
  
  
  
         <div id="mail">
          <div class="col-md-12">
                  <div class="inboHove">
                    <span>INBOX <span class="caret"></span></span>
                  <div class="dropMenu">
                  <ul>
                    <li role="presentation"><a href="#mailsMain">Index</a></li>
                    <li role="presentation"><a>Send</a></li>
                  </ul>
                </div>
                </div>
                </div>
  
          <button class="deleteTopBt" style="visibility: hidden;
      opacity: 0;">Declined</button> <button class="accepTopBt" style="visibility: hidden;
      opacity: 0;">Accepted</button>
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Inbox </h2> -->
           <div class="dashMainCont">
             <div class="dashMainInner">
  
  
  
              <div class="panel with-nav-tabs panel-default">
                  <div class="panel-heading">
                          <ul class="nav nav-tabs">
                              <li class="active"><a href="#tab1defaultt" data-toggle="tab">Pending</a></li>
                              <li><a href="#tab2defaultt" data-toggle="tab">Accepted</a></li>
                              <li><a href="#tab3defaultt" data-toggle="tab">Declined</a></li>
                          </ul>
                  </div>
                  <div class="panel-body">
                      <div class="tab-content">
                          <div class="tab-pane fade in active" id="tab1defaultt">
                            <div class="row">
                              <div class="col-md-6">
                               <div class="mailDiv">
                                <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                                 <div class="mailContDiv">
                                   <h4 class="mailHead">Interest Request</h4>
                                   <p>Swati Sonawane</p>
                                   <p>(LR2234) has liked your profile and has send an interest to you.</p>
                                   <button class="acceptBt">Accept</button>
                                   <button class="deleteBt">Decline</button>
                                 </div>
                               </div>
                             </div>
                             <div class="col-md-6">
                               <div class="mailDiv">
                                <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                                 <div class="mailContDiv">
                                   <h4 class="mailHead">Add Photo Request</h4>
                                   <p>Swati Sonawane</p>
                                   <p>(LR2234) has requested you to add photo,would you like to upload it now ?</p>
                                   <button class="acceptBt">Upload Photo</button>
                                 </div>
                               </div>
                             </div>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="tab2defaultt">
                              <div class="row">
                                <div class="col-md-6">
                                 <div class="mailDiv">
                                  <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                                   <div class="mailContDiv">
                                     <h4 class="mailHead">Accepted This Profile</h4>
                                     <p>Swati Sonawane</p>
                                     <p>(LR2234) You have Accepted This request.</p>
                                     <button class="acceptBt" style="cursor: no-drop; background: #dcdcdc;">Accepted</button>
                                   </div>
                                 </div>
                               </div>
                              </div>
                          </div>
                          <div class="tab-pane fade" id="tab3defaultt">
                              <div class="row">
                              <div class="col-md-6">
                                 <div class="mailDiv">
                                  <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                                   <div class="mailContDiv">
                                     <h4 class="mailHead">Deslined This Profile</h4>
                                     <p>Swati Sonawane</p>
                                     <p>(LR2234) You have Deslined This request.</p>
                                     <!-- <button class="acceptBt" style="cursor: no-drop; background: #dcdcdc;">Accepted</button> -->
                                   </div>
                                 </div>
                               </div>
                               <div class="col-md-6">
                                 <div class="mailDiv">
                                  <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                                   <div class="mailContDiv">
                                     <h4 class="mailHead">Deslined This Profile</h4>
                                     <p>Swati Sonawane</p>
                                     <p>(LR2234) You have Deslined This request.</p>
                                     <!-- <button class="acceptBt" style="cursor: no-drop; background: #dcdcdc;">Accepted</button> -->
                                   </div>
                                 </div>
                               </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
  
               
             </div>
           </div>
         </div>
  
         <div id="sent">
           
         </div>
  
         <div id="notification">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Notification</h2> -->
           <div class="dashMainCont notiMain">
             <div class="dashMainInner">
               <div class="row mailDivMain">
                 <div class="col-md-6">
                   <div class="mailDiv">
                    <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                     <div class="mailContDiv">
                       <h4 class="mailHead">Swati has accepted your interest.</h4>
                       <h4 class="date">2 months ago</h4>
                       <span><a href="#viewNotification"><i class="fa fa-eye"></i></a></span>
                     </div>
                   </div>
                 </div>
                 <div class="col-md-6">
                   <div class="mailDiv">
                    <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                     <div class="mailContDiv">
                       <h4 class="mailHead">Swarupa has sent request to add your photo.</h4>
                       <h4 class="date">Yesterday</h4>
                       <span><a href="#viewNotification"><i class="fa fa-eye"></i></a></span>
                     </div>
                   </div>
                 </div>
                 <div class="col-md-6">
                   <div class="mailDiv">
                    <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                     <div class="mailContDiv">
                       <h4 class="mailHead">Swarupa has sent request to add your photo.</h4>
                       <h4 class="date">Yesterday</h4>
                       <span><a href="#viewNotification"><i class="fa fa-eye"></i></a></span>
                     </div>
                   </div>
                 </div>
                 <div class="col-md-6">
                   <div class="mailDiv">
                    <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                     <div class="mailContDiv">
                       <h4 class="mailHead">Swarupa has sent request to add your photo.</h4>
                       <h4 class="date">Yesterday</h4>
                       <span><a href="#viewNotification"><i class="fa fa-eye"></i></a></span>
                     </div>
                   </div>
                 </div>
                 <div class="col-md-6">
                   <div class="mailDiv">
                    <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                     <div class="mailContDiv">
                       <h4 class="mailHead">Swarupa has sent request to add your photo.</h4>
                       <h4 class="date">Yesterday</h4>
                       <span><a href="#viewNotification"><i class="fa fa-eye"></i></a></span>
                     </div>
                   </div>
                 </div>
                 <div class="col-md-6">
                   <div class="mailDiv">
                    <div class="mailProDiv"><div class="mailImgDiv"><img src="assets/images/matches2.jpg"></div></div>
                     <div class="mailContDiv">
                       <h4 class="mailHead">Swarupa has sent request to add your photo.</h4>
                       <h4 class="date">Yesterday</h4>
                       <span><a href="#viewNotification"><i class="fa fa-eye"></i></a></span>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div id="viewNotification">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Notification Details</h2> -->
           <div class="dashMainCont">
              <div class="dashMainInner">
                <div class="matchesInner">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="matchesDiv proDetailMain">
                          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                          <div class="matchesPic">
                            <div class="coun">10</div>
                            <div class="leftA"> < </div>
                            <div class="rightA"> > </div>
                              <img src="assets/images/matches1.jpg">
                          </div>
                          <div class="callDetais">
                              <div class="floatNeed">
                              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc"></i></span>
                              <span data-tooltip="Shortlisted"><i class="fa fa-star starIc"></i></span>
                              </div>
                              <h4 class="callDetaillsName">Akshda Mishra (BR105)</h4>
                              <p>30 Yrs, 5.5ft</p>
                              <p>BA, Nashik, Maharashtra.</p>
                              <button type="button" class="proDetailsBt">
                                <a href="#proDetails"> View Profile</a>
                              </button>
                          </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
  
  
  
  
  
  
  
  
         <div id="proDetails">
           <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Profile Details</h2> -->
           <div class="samplecover-spin" id="samplecover-spin" *ngIf="prodetailspinner"></div>
           <div class="dashMainCont proDetailMain" *ngIf="!prodetailspinner">
              <div class="dashMainInner">
                    <div class="row">
                      <div class="col-md-12">
  
                        <div class="slider-box-block" data-slider-autoplay="" data-slider-counter="" data-slider-arrow="">
                          <div class="slider-box-block__left"><span class="slider-box-block__arrow--left"></span></div><div class="slider-box-block__right"><span class="slider-box-block__arrow--right"></span></div><div class="slider-box-block__center">  
                            <div id="slider-img-1" class="slider-box-block__center--item-slider-hidden slider-box-block__center--item-slider-active active--fade active--slide">
                                <img src="assets/images/matches1.jpg" class="imgLightBox">
                              </div>          
                            <div id="slider-img-2" class="slider-box-block__center--item-slider-hidden">
                                <img src="assets/images/matches1.jpg" class="imgLightBox"> 
                              </div>          
                            <div id="slider-img-3" class="slider-box-block__center--item-slider-hidden slider-box-block__center--item-slider-active active--slide-left">
                                <img src="assets/images/matches1.jpg" class="imgLightBox">
                              </div>         
                            <div id="slider-img-4" class="slider-box-block__center--item-slider-hidden"> 
                                <img src="assets/images/matches1.jpg" class="imgLightBox">
                              </div>      
                          <div id="slider-box-block__counter"><span id="slider-box-block__counter--start">1</span>/<span id="slider-box-block__counter--end">4</span></div></div>            
                        </div>
  
                         
                      </div>
  
                      <div class="col-md-12">
                        <div class="proDetailsDiv">
            <h4 class="proHead proHead2"><i class="fa fa-user"></i> About My {{memberdetailsresult.MemberProfileFor}}</h4>
            <div class="proInnerCont">
             <p>{{memberdetailsresult.MemberAboutMe}}</p>
            </div>
  
            <h4 class="proHead"><i class="fa fa-list"></i> Personal Details</h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Full Name</b><span>: {{memberdetailsresult.MemberFullName}}</span></li>
               <li><b>Gender</b><span>: {{memberdetailsresult.MemberGender}}</span></li>
               <li><b>Date of Birth </b><span>: {{memberdetailsresult.MemberDOB}}</span></li>
               <li><b>Profile ID </b><span>: ({{memberdetailsresult.MemberProfileID}})</span></li>
               <li><b>Age</b><span>: {{memberdetailsresult.MemberAge}}</span></li>
               <li><b>height</b><span>: {{memberdetailsresult.MemberHeight}} (173 cm)</span></li>
               <li><b>Birth Time</b><span>: {{memberdetailsresult.MemberBirthTime}}</span></li>
               <li><b>Birth Place</b><span>: {{memberdetailsresult.MemberBirthPlace}}</span></li>
               <li><b>Marital Status</b><span>: {{memberdetailsresult.MemberMartialStatus}}</span></li>
               <li><b>Mother Tounge</b><span>: {{memberdetailsresult.MemberMotherTongue}}</span></li>
               <li><b>Complexion</b><span>: {{memberdetailsresult.MemberComplexion}}</span></li>
               <li><b>Blood Group</b><span>: {{memberdetailsresult.MemberBloodGroup}}</span></li>
               <li><b>Disability</b><span>: {{memberdetailsresult.MemberDisability}}</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-globe"></i> Religion Details</h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Religion</b><span>: {{memberdetailsresult.MemberRelgName}}</span></li>
               <li><b>Caste</b><span>: {{memberdetailsresult.MemberCasteName}}</span></li>
               <li><b>Sub Caste </b><span>: {{memberdetailsresult.MemberSubCastName}}</span></li>
               <li><b>Gotra</b><span>: {{memberdetailsresult.MemberGothra}}</span></li>
               <li><b>Raas</b><span>: {{memberdetailsresult.MemberRaas}}</span></li>
               <li><b>Charan</b><span>: {{memberdetailsresult.MemberCharan}}</span></li>
               <li><b>Gana</b><span>: {{memberdetailsresult.MemberGana}}</span></li>
               <li><b>Nakshtra</b><span>: {{memberdetailsresult.MemberNakshtra}}</span></li>
               <li><b>Nadi</b><span>: {{memberdetailsresult.MemberNadi}}</span></li>
               <li><b>Mangal Dosh</b><span *ngIf="memberdetailsresult.MemberDosh !== '0'">: 
               Yes</span><span *ngIf="memberdetailsresult.MemberDosh === '0'">: No</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-drivers-license"></i> Contact Details</h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Contact Number</b><span *ngIf="memberdetailsresult.MemberContactNo !== '0'">:
                 {{memberdetailsresult.MemberContactNo}}</span><span *ngIf="memberdetailsresult.MemberContactNo === '0'">: XXXXXXXXXX</span></li>
               <li><b>Email ID</b><span *ngIf="memberdetailsresult.MemberEmailID !== '' ">: {{memberdetailsresult.MemberEmailID}}</span><span *ngIf="memberdetailsresult.MemberEmailID ==='' ">: XXX@XXXX.com</span></li>
               <li class="upgradeBtListing"><b><i class="fa fa-lock"></i> &nbsp; To unlock the contact detail</b><span>: <button><a href="#upgradeView">Upgrade Now</a></button></span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-map-marker"></i> Address Details</h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Address</b><span>: {{memberdetailsresult.MemberAddress}}</span></li>
               <li><b>Resident State</b><span>: {{memberdetailsresult.MemberStateName}}</span></li>
               <li><b>Resident City / District</b><span>: {{memberdetailsresult.MemberCityName}}</span></li>
               <li><b>Resident Taluka</b><span>: {{memberdetailsresult.MemberTaluka}}</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-drivers-license"></i> Professional Details</h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Education</b><span>: {{memberdetailsresult.MemberEductionGroupName}}</span></li>
               <li><b>Employee in</b><span>: {{memberdetailsresult.MemberEmployedIn}}</span></li>
               <li><b>Occupation</b><span>: {{memberdetailsresult.MemberOccupation}}</span></li>
               <li><b>Job Country</b><span>: {{memberdetailsresult.MemberJobCountryName}}</span></li>
               <li><b>Job City</b><span>: {{memberdetailsresult.MemberJobCity}}</span></li>
               <li><b>Annual Income</b><span>: {{memberdetailsresult.MemberAnnualIncome}}</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-users"></i> Family Details</h4>
            <div class="proInnerCont">
             <ul>
               <li><b>Father Name</b><span>: {{memberdetailsresult.MemberFatherName}}</span></li>
               <li><b>Father Occupation</b><span>: {{memberdetailsresult.MemberFatherOccupation}}</span></li>
               <li><b>Father Contact No</b><span>: {{memberdetailsresult.MemberFatherContactNo}}</span></li>
               <li><b>Mother's Name</b><span>: {{memberdetailsresult.MemberMotherName}}</span></li>
               <li><b>Mother's Occupation</b><span>: {{memberdetailsresult.MemberMotherOccupation}}</span></li>
               <li><b>Brother</b><span>: {{memberdetailsresult.MemberBrotherNos}}</span></li>
               <li><b>Sister</b><span>: {{memberdetailsresult.MemberSisterNos}}</span></li>
               <li><b>Family Status</b><span>: {{memberdetailsresult.MemberFamilyStatus}}</span></li>
             </ul>
            </div>
  
            <h4 class="proHead"><i class="fa fa-chain"></i> Profile Matching Your Location</h4>
            <div class="proInnerCont">
              <div class="proCarouselMain">
                <div class="span12">          
                    <div class="carousel slide" id="myCarousel">
                        <div class="carousel-inner">
  
  
                            <!-- <div class="item">
                                    <ul class="thumbnails">
                                        <li class="span3">
                                            <div class="thumbnail">
                                                <a href="#"><img src="assets/images/matches1.jpg" alt=""></a>
                                            </div>
                                            <div class="caption">
                                                <h4>Neha Naksh</h4>
                                                <p>30 Years Old</p>
                                            </div>
                                        </li>
                                        
                                    </ul>
                              </div> -->
                              
                              
                              <!-- /Slide1 --> 
  
  
                            <div class="item active" *ngFor="let matchinglocation of profilematchlocation">
                                    <ul class="thumbnails">
                                        <li class="span3">
                                            <div class="thumbnail">
                                                <a href="#"><img [src]='photopath+matchinglocation.MemberPhotoPath' alt=""></a>
                                            </div>
                                            <div class="caption">
                                                <h4>{{matchinglocation.MemberName}}</h4>
                                                <p>{{matchinglocation.MemberAge}} Years Old</p>
                                            </div>
                                        </li>
                                        
                                    </ul>
                              </div>
                              
                              
                              <!-- /Slide2 --> 
                        </div>
                        
                        <div class="control-box">                            
                            <a data-slide="prev" href="#myCarousel" class="carousel-control left">‹</a>
                            <a data-slide="next" href="#myCarousel" class="carousel-control right">›</a>
                        </div>                       
                    </div>          
                </div>                
              </div>        
            </div>
  
            <h4 class="proHead"><i class="fa fa-handshake-o"></i> Partner Preference Match</h4>
            <div class="proInnerCont">
              <div class="bothMatcMain">
                <div class="bothMatches">
                  <img [src]='profilePic'>
                </div>
                <div class="bothMatches2">
                  <img [src]='photopath+memberdetailsresult.MemberPhotoPath'>
                </div>
  
                <div class="bothMatCont">
                  <p style="margin-bottom: 0; font-weight: 600;">Matched Preference</p>
                  <p style="color: green;">15/16</p>
                </div>
              </div>
  
              <ul>
                 
  
               <li *ngFor="let preferenceval of partnerpreference"><b>{{preferenceval.Title}}</b><span>: 
                 {{preferenceval.DisplayValue|stringreplacepipe}}<i *ngIf="preferenceval.Result === 'Match'" class="fa fa-check bothMatcMark"></i> <i *ngIf="preferenceval.Result !== 'Match'" class="fa fa-close bothNotMatcMark"></i>
                </span></li>
               
               <li class="upgradeBtListing"><b><i class="fa fa-lock"></i> &nbsp; Upgrade Membership</b><span>: <button><a href="#upgradeView">Upgrade Now</a></button></span></li>
                 
              </ul>
            </div>
  
  
        </div>
                      </div>
                    </div>
              </div>
            </div>
          </div>
  </div>
  </div>    
  
  <!-- Modal -->
  
  <!-- About Modal -->
  <div class="modal fade" id="proEditAbout" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Profile</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
        <textarea class="textAria">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
        </textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  <!-- Personal Details Modal -->
  <div class="modal fade" id="personalDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Profile</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <input type="text" name="" placeholder="Profile ID" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Full Name" class="inputFil">
            </div>
            <div class="col-md-6">
              <div class="radioSt">
                <input type="radio" id="male" name="gender" value="male" class="maleInp">
                <label for="male" class="maleStyle">Male</label>
                <input type="radio" id="female" name="gender" value="female" class="maleInp">
                <label for="female" class="maleStyle">Female</label>
              </div>
            </div>
            <div class="col-md-6">
              <input type="date" name="" placeholder="Date Of Birth" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Age</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Height</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="time" name="" placeholder="Birth Time" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Birth Place" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Marital Status</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Mother Tounge</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Complexion" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Blood Group</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Disability</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Religion Details Modal -->
  <div class="modal fade" id="rligionDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Profile</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Religion</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Caste</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Sub Caste" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Gotra" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Raas" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Charan" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Gana" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Nakshtra" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Nadi" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Mangal Dosh</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Contact Details Modal -->
  <div class="modal fade" id="contactDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Contact</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <input type="text" name="" placeholder="Contact Details" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Email ID" class="inputFil">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Address Details Modal -->
  <div class="modal fade" id="addressDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Address</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <input type="text" name="" placeholder="Address" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Resident State</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Resident City / District</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Resident Taluka</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Professional Details Modal -->
  <div class="modal fade" id="professionalDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Professional Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Education</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Employee in" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Occupation</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Job Country</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Job City</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Annual Income</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  
  <!-- Family Details Modal -->
  <div class="modal fade" id="familyDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Family Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <input type="text" name="" placeholder="Father Name" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Father Occupation</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Father Contact No" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Mother's Name" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Mother's Occupation</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Brother" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Sister" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Family Status</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Edit Preferences Modal -->
  <div class="modal fade" id="editPreferences" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Edit Preferences</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Bride Age</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Height</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Maritial Status</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Disability</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Religious Preferences Modal -->
  <div class="modal fade" id="religiousPreferencesEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Religious Preferences</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Mother Tongue</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Physical Status</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Religion</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Caste" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Sub Caste" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Nakshtra" class="inputFil">
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Mangal Dosh</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  
  <!-- Location Preferences Modal -->
  <div class="modal fade" id="locationPreferencesEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Location Preferences</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Country</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>State</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>City</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Job Country</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Professional Preferences Modal -->
  <div class="modal fade" id="professionalPreferencEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Professional Preferences</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
          <div class="row">
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Education</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="inputFil inputFilSelect">
                  <option>Occupation</option>
                  <option>Select</option>
                  <option>Select</option>
              </select>
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Employee In" class="inputFil">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- About Partner Modal -->
  <div class="modal fade" id="aboutPartnerEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update About Partner</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  
            <textarea class="textAria">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            </textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Change Password Modal -->
  <div class="modal fade" id="changePassword" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Change Your Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        <div class="row">  
          <div class="col-md-6">
              <input type="text" name="" placeholder="Old Passsword" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="New Passsword" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Confirm Passsword" class="inputFil">
            </div>
            <div class="col-md-6">
              <input type="text" name="" placeholder="Hint" class="inputFil">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Change Passsword</button>
        </div>
    </div>
  </div>
  </div>
  
  <!-- Delete Your Account Modal -->
  <div class="modal fade" id="deleteAc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Delete Your Account</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">  
            <!-- <div class="col-md-12">
              <h5 class="sureDelete"><i class="fa fa-trash"></i>Are you sure you want to delete</h5>
            </div> -->
            <div class="col-md-12">
              <p>
                <input type="radio" id="married1" name="gender" value="male" class="maleInp">
                <label for="married1" class="maleStyle">Married/Marriage Fixed</label>
              </p>
              <p>
                <input type="radio" id="married2" name="gender" value="female" class="maleInp">
                <label for="married2" class="maleStyle">Other Reason</label>
              </p>
            </div>
            <div class="col-md-12">
              <h5 class="deleHeading">Select The Source</h5>
            </div>
            <div class="col-md-12">
                <p>
                <input type="radio" id="through1" name="gender" value="male" class="maleInp">
                <label for="through1" class="maleStyle">Through Marathi Reshimgathi</label>
              </p>
              <p>
                <input type="radio" id="through2" name="gender" value="female" class="maleInp">
                <label for="through2" class="maleStyle">Through Other Site</label>
              </p>
              <p>
                <input type="radio" id="through3" name="gender" value="female" class="maleInp">
                <label for="through3" class="maleStyle">Through Other Source</label>
              </p>
            </div>
            <div class="col-md-12">
              <h5 class="deleHeading">Date Of Marriage (Optional)</h5>
            </div>
            <div class="col-md-12">
              <p>Please Select Your Date Of Marriege</p>
              <input type="date" class="deleDate" placeholder="" name="">
            </div>
            <div class="col-md-12">
              <h5 class="deleHeading">Share Your Experience) (Optional)</h5>
              <textarea class="deleText" placeholder="Please Share Your Experience Here"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          <button type="button" class="btn btn-primary">Delete Account</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Terms And Conditions Modal -->
  <div class="modal fade" id="TermsAndCondi" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Terms And Conditions</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">  
            <div class="col-md-12">
              <b class="termsAndNo">1. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">2. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">3. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">4. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
    </div>
  </div>
  </div>
  
  
  <!-- Privacy Policy Modal -->
  <div class="modal fade" id="privacyPolicy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Privacy Policy</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">  
            <div class="col-md-12">
              <b class="termsAndNo">1. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">2. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">3. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
              <b class="termsAndNo">4. <span class="termsHead">Curabitur nec porttitor nisl, id varius justo.</span></b>
              <p class="termsAndText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis posuere metus et gravida. Curabitur nec porttitor nisl, id varius justo. Nam convallis sem ornare leo scelerisque, vel dapibus tortor dictum. Etiam et metus bibendum, fringilla nunc quis, viverra sem.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
    </div>
  </div>
  </div>
  
  
  
  <!-- Rate Us Modal -->
  <div class="modal fade" id="raeUsM" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Rate Us</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">  
            <div class="col-md-12">
              <div class="rateMain">
                <h1>Marathi Reshimgathi</h1>
                  <fieldset class="rating">
                      <input type="radio" id="star5" name="rating" value="5" /><label class = "full" for="star5" title="Awesome - 5 stars"></label>
                      <input type="radio" id="star4half" name="rating" value="4 and a half" /><label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>
                      <input type="radio" id="star4" name="rating" value="4" /><label class = "full" for="star4" title="Pretty good - 4 stars"></label>
                      <input type="radio" id="star3half" name="rating" value="3 and a half" /><label class="half" for="star3half" title="Meh - 3.5 stars"></label>
                      <input type="radio" id="star3" name="rating" value="3" /><label class = "full" for="star3" title="Meh - 3 stars"></label>
                      <input type="radio" id="star2half" name="rating" value="2 and a half" /><label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>
                      <input type="radio" id="star2" name="rating" value="2" /><label class = "full" for="star2" title="Kinda bad - 2 stars"></label>
                      <input type="radio" id="star1half" name="rating" value="1 and a half" /><label class="half" for="star1half" title="Meh - 1.5 stars"></label>
                      <input type="radio" id="star1" name="rating" value="1" /><label class = "full" for="star1" title="Sucks big time - 1 star"></label>
                      <input type="radio" id="starhalf" name="rating" value="half" /><label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
                  </fieldset>
                  <p>How Was Your Experience ?</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Will give later</button>
          <button type="button" class="btn btn-primary">Submit</button>
        </div>
    </div>
  </div>
  </div>
  
  
  
  
  
  <div class="searchMainDiv">
    <div class="searchInPu">
            <input type="text" name="" placeholder="SEARCH">
            <div class="searBt"><i class="fa fa-search"></i></div>
    </div>
    <i class="fa fa-close searchClose"></i>
  </div>
  
  
  <div class="photoGalleryMod">
  <div id="myModal" class="modal">
    <span class="close cursor" onclick="closeModal()">&times;</span>
    <div class="modal-content">
  
      <div class="mySlides">
        <div class="numbertext">10</div>
        <img src="assets/images/pho1.jpg" style="width:100%">
      </div>
  
      <div class="mySlides">
        <div class="numbertext">10</div>
        <img src="assets/images/pho2.jpg" style="width:100%">
      </div>
  
      <div class="mySlides">
        <div class="numbertext">10</div>
        <img src="assets/images/pho1.jpg" style="width:100%">
      </div>
      
      <div class="mySlides">
        <div class="numbertext">4 / 4</div>
        <img src="assets/images/pho2.jpg" style="width:100%">
      </div>
      
      <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
      <a class="next" onclick="plusSlides(1)">&#10095;</a>
  
      <div class="caption-container">
        <p id="caption"></p>
      </div>
  
  
      <div class="column">
        <img class="demo cursor" src="assets/images/pho1.jpg" style="width:100%" onclick="currentSlide(1)" alt="Nature and sunrise">
      </div>
      <div class="column">
        <img class="demo cursor" src="assets/images/pho2.jpg" style="width:100%" onclick="currentSlide(2)" alt="Snow">
      </div>
      <div class="column">
        <img class="demo cursor" src="assets/images/pho1.jpg" style="width:100%" onclick="currentSlide(3)" alt="Mountains and fjords">
      </div>
      <div class="column">
        <img class="demo cursor" src="assets/images/pho2.jpg" style="width:100%" onclick="currentSlide(4)" alt="Northern Lights">
      </div>
    </div>
  </div>
  </div>
  
  
  
  
  <!-- OTP Model -->
  <div class="modal fade" id="global-modal" role="dialog">
    <div class="modal-dialog modal-lg">
      <!--Modal Content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 style="color: #fff">Verify Account</h4>
          <button type="button" class="close" style="margin-top: -35px; font-size: 28px;" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
  
        </div>
        <div class="modal-body" style="padding: 30px;">
          <div class="otp-main">
            <img src="assets/images/otp.png">
            <p>Will receive a 4-digit confirmation code via SMS to your registered mobile number.</p>
            <h4>Please Enter OTP</h4>
            <div style="width: 100%; margin: 0 auto; margin-top:5px;" class="otpCont flex spaceBetween">
              <div class="otOut">
              <input class="otSc" type="number" maxlength="1">
              <input class="otSc" type="number" maxlength="1">
              <input class="otSc" type="number" maxlength="1">
              <input class="otSc" type="number" maxlength="1">
              </div>
            </div>
            <button data-toggle="modal" data-target="#otpSucModal" class="verifyBt" style="background-color: #0093dd" data-dismiss="modal" aria-label="Close">Verify</button>
            <a href="/"><button class="verifyBt">Login</button></a>
            <p style="margin-top: 18px;">Need help? Call 9881789836</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- Upload Photo -->
  <div class="modal fade" id="upPhoto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Upload Your Photo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <div class="upPicOuter">
            <label class=newbtn>
                <img id="blah2" src="assets/images/default-avatar.jpg" >
                <i class="fa fa-pencil pencilUpPic"></i>
            </label>
          </div>
          <p>Profile with photo give 10 times better response. Add qiality photos</p>
          <p class="upPhoConText">You can make your photo visibel ONLY to people you send interests to and people
          you accept. Your photo will not be visibel to the rest of the members on the site. 
        Do you want to upload your photos now ?</p>
          <input id="pic" class='pis' onchange="readURL(this);" type="file" >
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancle</button>
          <button type="button" class="btn btn-primary">Upload photo</button>
        </div>
    </div>
  </div>
  </div>
  
  <!-- Modal Success Popup -->
  <div class="modal fade success-popup" id="otpSucModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="    margin-top: 0;"><span aria-hidden="true">×</span></button>
          <h4 class="modal-title" id="myModalLabel">Thank You !</h4>
        </div>
        <div class="modal-body text-center">
           <img src="assets/images/suces.png" style="width: 67px">
           <h4>Verified</h4>
            <p>Your account has been verified</p>
            <button data-toggle="modal" data-target="#upPhoto" data-dismiss="modal" class="btn btn-primary">Ok</button>
        </div>
        
      </div>
    </div>
  </div>
  
  
  <!-- Call Popup -->
  <div class="modal fade success-popup" id="callModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="    margin-top: 0;"><span aria-hidden="true">×</span></button>
          <h4 class="modal-title" id="myModalLabel">Thank You !</h4>
        </div> -->
        <div class="modal-body text-center">
          <i class="fa fa-close callPopDele" data-dismiss="modal"></i>
           <div class="calImgDiv">
            <img src="" style="width: 67px">
           </div>
           <h4>Contact Akshda Meshra on</h4>
            <p><i class="fa fa-phone"></i> &nbsp; 9881789836</p>
        </div>
        <div class="modal-footer">
          <p>Upgrade now to view her phone number</p>
          <button class="callUpgraBT"><a href="#upgradeView">Upgrade now</a></button>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  
  