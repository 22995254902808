import { RegistrationService } from '../services/registration.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import { LoginService } from '../services/login.service';
import { WebsiteConstants } from '.././global/website-constants';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

    logloading:boolean;logspinner:boolean; basicForm: FormGroup; secondForm: FormGroup;
    thirdForm: FormGroup; fourForm: FormGroup; isSubmitted = false; isSubmitted1 = false; isSubmitted2 = false; religionlist:any; castelist:any; doshval:any=0; disabilityval:any='No'; noChildrenval: any; ReligionID:any; countrylist:any;countryId:any; statelist:any;stateId:any; citylist:any; employeeInId:any; employeeindesc:any; candidatenoun:any; candiatehighedu:any; hiddenFileValue:any=0;
    gothralist:any; subcastlist:any;subCastId:any; heightlist:any; bloodgrplist:any; fullnameval:any; birthnameval:any; birthnamevalformat:any; genderval:any; religionval:any; motherTongueval:any; emailidval:any; phonenoval:any; passwordval:any; createprofileval:any;casteval:any;subcasteval:any;
    gothraval:any; MaritalStatusArray :any[]; profileIdFor:any; maxDate: Date; today: Date;
    isShown:boolean = false; isShown1:boolean = false; isShown2:boolean = false; isShown3:boolean = false; isChild:boolean = false; isMeridian = true; isDisabled = true; myTime = new Date(); ismeridian: boolean = false; date1:any; date2:any; newdate:any; pastYear:any; Difference_In_Time:any; Difference_In_Days:any; previousDate: Date; maritalstatusval:any;
    candidateheightval:any; birthtimeval:any; birthplaceval:any; complexionval:any; bloodgroupval:any;
    childrenStayingval:any; EducationTitle:any; Educationlist:any; Occupationlist:any; highesteducationval:any=null; countryIdval:any; stateIdval:any; 
    countryval:any; stateval:any; cityval:any; talukaval:any; highesteducationaval:any; othereducationval:any; employeeinval:any; occupationval:any; annualincomeval:any; jobcountryval:any;jobcityval:any; fatheroccupationval:any; fathernameval:any; mothernameval:any; motheroccupationval:any; gaurdiancontactval:any; familystatusval:any; familytypeval:any;familyvalueval:any; aboutusdescriptionval:any; occupationdesc:any;
    createProfiledes:any; createProfilenoun:any; createfor1:any; createfor2:any; AboutProfile:any;
    fileToUpload:File = null;  employeeInIdval:any; heightIdval:any;
    subCastIdval:any; educationpostval:any; OccupationIdval:any;
    postbirthval:any; postbirthvalformat:any; increaseyear:any;
    postbloodval:any; postbloodvalformat:any; sitetitle = WebsiteConstants.SiteTitle;
    SiteUrl = WebsiteConstants.SiteUrl; 
    masterUrl = WebsiteConstants.MasterUrl;
    imageUrl:string=this.masterUrl+'DefaultAvatar/Male/default_male.png';

    constructor(private route:Router, private fBuilder: FormBuilder, private regservice:RegistrationService, private service:LoginService) 
    { 
      
      
      if(localStorage.getItem("disability")===null)
      { 
          localStorage.setItem("disability",'No');
      }

      if(localStorage.getItem("dosh")===null)
      {
          localStorage.setItem("dosh","0");
      }

      
        this.maxDate = new Date(); this.today = new Date();
        this.basicForm = this.fBuilder.group({
        "createProfile": new FormControl("", [ Validators.required]),
        "fullname": new  FormControl("", Validators.compose([
        Validators.required,Validators.pattern('^[a-zA-Z \-\']+')
        ])),
        "birthdate": new FormControl("", [ Validators.required ]),
        "gender": new FormControl("", [ Validators.required ]),
        "religion": new FormControl("", [ Validators.required ]),
        "motherTongue": new FormControl("", [ Validators.required ]),
        "emailid": new FormControl("", Validators.compose([
        Validators.required,Validators.pattern(/^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/)
        ])),
        "phoneno": new FormControl("", Validators.compose([
        Validators.required,Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
        ])),
        "password": new FormControl("", Validators.compose([Validators.required,Validators.minLength(6)]))
        });

        this.secondForm = this.fBuilder.group({
        "caste": new FormControl("", [ Validators.required]),
        "subcaste": new FormControl("", [ Validators.required ]),
        "gothra": new FormControl(""),
        "dosh": new FormControl(""),
        "maritalstatus": new FormControl("", [ Validators.required ]),
        "noChildren": new FormControl(""),
        "childrenStaying": new FormControl(""),
        "candidateheight": new FormControl("", [ Validators.required ]),
        //"birthtime": new FormControl("", [ Validators.required ]),
        "birthtime": new FormControl(""),
        "birthplace": new FormControl(""),
        "complexion": new FormControl(""),
        //"bloodgroup": new FormControl("", [ Validators.required ]),
        "bloodgroup": new FormControl("", [ Validators.required ]),
        "disability": new FormControl("")
        });


      this.thirdForm = this.fBuilder.group({
      "country": new FormControl("", [ Validators.required]),
      "state": new FormControl("", [ Validators.required ]),
      "city": new FormControl("", [ Validators.required ]),
      "taluka": new FormControl(""),
      "highesteducation": new FormControl("", [ Validators.required ]),
      "othereducation":new FormControl(""),
      "employeein": new FormControl("", [ Validators.required ]),
      "occupation": new FormControl("", [ Validators.required ]),
      "annualincome": new FormControl(""),
      "jobcountry": new FormControl(""),
      "jobcity": new FormControl(""),
      "fathername": new FormControl("", [ Validators.required ]),
      "fatheroccupation": new FormControl("", [ Validators.required ]),
      "mothername": new FormControl("", [ Validators.required ]),
      "motheroccupation": new FormControl("", [ Validators.required ]),
      "gaurdiancontact": new FormControl("", [ Validators.required ]),
      "familystatus": new FormControl("", [ Validators.required ]),
      "familytype": new FormControl("", [ Validators.required ]),
      "familyvalue": new FormControl("", [ Validators.required ])
      })


      this.fourForm = this.fBuilder.group({
   
        profilePhoto: [''],
        // "profilePhoto": new FormControl(""),
        "hiddenFile": new FormControl(""),
        "fewlineyou": new FormControl("", [ Validators.required ]),
        "fewlinepartner": new FormControl("")
        
      })


  }

  ngOnInit(): void 
  {
    
    $(document).ready(function()
    { 
        $("#first").show();
        $("#second").hide();
        $("#third").hide();
        $("#four").hide();
        $("#noChild").hide();
    })
      this.appdetails()    
      this.getReligionDetails();
      this.getCasteDetails();
      this.getCountryDetails();
      this.getGothraDetails();
      this.getHeightDetails();
      this.getBloodGrpDetails();
      this.getEducationDetails();
      this.getOccupationDetails();

      this.createprofileval = localStorage.getItem("createProfile");
      this.fullnameval = localStorage.getItem("fullname");
      if(localStorage.getItem("birthdate")===null)
      {  
        this.birthnameval ="";
      }
      else
      {
        this.birthnamevalformat = localStorage.getItem("birthdate").split("-");
        this.birthnameval = new Date(this.birthnamevalformat[2], this.birthnamevalformat[1]-1, this.birthnamevalformat[0])
      }

      this.genderval = localStorage.getItem("gender");
      this.religionval = localStorage.getItem("religion");
      this.motherTongueval = localStorage.getItem("motherTongue");
      this.emailidval = localStorage.getItem("emailid");
      this.phonenoval = localStorage.getItem("phoneno");
      this.passwordval = localStorage.getItem("password");
      this.casteval = localStorage.getItem("caste");
      this.subcasteval = localStorage.getItem("subcaste");
      if(localStorage.getItem("gothra")!==null && localStorage.getItem("gothra")!='')
        {
          this.gothraval = localStorage.getItem("gothra"); 
        }
        else
        {
            this.gothraval = null;
        }

      this.doshval = localStorage.getItem("dosh");
      this.maritalstatusval = localStorage.getItem("maritalstatus");
      this.candidateheightval = localStorage.getItem("candidateheight");
      this.birthtimeval = localStorage.getItem("birthtime");
      this.birthplaceval = localStorage.getItem("birthplace");

      if(localStorage.getItem("complexion")!==null && localStorage.getItem("complexion")!='')
        {
          this.complexionval = localStorage.getItem("complexion");
        }
        else
        {
            this.complexionval = null;
        }


      
      if(localStorage.getItem("bloodgroup")!==null && localStorage.getItem("bloodgroup")!='')
        {
          this.bloodgroupval = localStorage.getItem("bloodgroup");
        }
        else
        {
            this.bloodgroupval = null;
        }
      this.disabilityval = localStorage.getItem("disability");
      this.noChildrenval = localStorage.getItem("noChildren");
      this.childrenStayingval = localStorage.getItem("childrenStaying");
      this.countryval = localStorage.getItem("country");
      this.stateval = localStorage.getItem("state");
      this.cityval = localStorage.getItem("city");
      this.talukaval = localStorage.getItem("taluka");
      this.highesteducationaval = localStorage.getItem("highesteducation");      
      this.othereducationval = localStorage.getItem("othereducation");
      this.employeeinval = localStorage.getItem("employeein");
      this.occupationval = localStorage.getItem("occupation");
      this.annualincomeval = localStorage.getItem("annualincome");
      if(localStorage.getItem("jobcountry")!==null)
        {
          this.jobcountryval = localStorage.getItem("jobcountry"); 
        }
        else
        {
          this.jobcountryval = null;
        }
      this.jobcityval = localStorage.getItem("jobcity");
      this.fatheroccupationval = localStorage.getItem("fatheroccupation");
      this.fathernameval = localStorage.getItem("fathername");
      this.mothernameval = localStorage.getItem("mothername");
      this.motheroccupationval = localStorage.getItem("motheroccupation");
      this.gaurdiancontactval = localStorage.getItem("gaurdiancontact");
      this.familystatusval = localStorage.getItem("familystatus");
      this.familytypeval = localStorage.getItem("familytype");
      this.familyvalueval = localStorage.getItem("familyvalue");
      

      if(this.genderval == "male" || this.genderval == "Male")
      {
        this.MaritalStatusArray=[
        {mstatus : "Never Married",mstatusval : "Never Married"},
        {mstatus :"Divorced" ,mstatusval : "Divorced"},
        {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
        {mstatus :"Widow" ,mstatusval : "Widow"}    
        ];
      }
      else
      {
        this.MaritalStatusArray=[
        {mstatus : "Never Married",mstatusval : "Never Married"},
        {mstatus :"Divorced" ,mstatusval : "Divorced"},
        {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
        {mstatus :"Widower" ,mstatusval : "Widower"}
        ];
      }

      if(this.birthnameval===null)
      {
          if(localStorage.getItem("gender")=="Male")
          {
              this.defaultDate('Male')
          }
          else
          {
            this.defaultDate('Female')
          }
      }

      if(localStorage.getItem("subcaste")!="" && localStorage.getItem("subcaste")!=null)
      {
          this.getSubCastDetails(localStorage.getItem("caste"))
      }
      else
      {
        this.getCasteDetails();
      }



      if(localStorage.getItem("country")!="" && localStorage.getItem("country")!=null)
      {
          this.onSelectCountry(localStorage.getItem("country"))
      }


      if(localStorage.getItem("employeein")!="" && localStorage.getItem("employeein")!=null)
      {
          this.onSelectOccupation(localStorage.getItem("employeein"))
      }


      
  }

  get createProfile() { return this.basicForm.get('createProfile'); } 
  get gender() { return this.basicForm.get('gender'); } 
  get fullname() { return this.basicForm.get('fullname'); } 
  get birthdate() { return this.basicForm.get('birthdate'); } 
  get religion() { return this.basicForm.get('religion'); } 
  get motherTongue() { return this.basicForm.get('motherTongue'); } 
  get emailid() { return this.basicForm.get('emailid'); } 
  get phoneno() { return this.basicForm.get('phoneno'); } 
  get password() { return this.basicForm.get('password'); } 

  get caste() { return this.secondForm.get('caste'); } 
  get subcaste() { return this.secondForm.get('subcaste'); } 
  get gothra() { return this.secondForm.get('gothra'); } 
  get dosh() { return this.secondForm.get('dosh'); } 
  get maritalstatus() { return this.secondForm.get('maritalstatus'); } 
  get noChildren() { return this.secondForm.get('noChildren'); }
  get childrenStaying() { return this.secondForm.get('childrenStaying'); }
  get candidateheight() { return this.secondForm.get('candidateheight'); } 
  get birthtime() { return this.secondForm.get('birthtime'); } 
  get birthplace() { return this.secondForm.get('birthplace'); } 
  get complexion() { return this.secondForm.get('complexion'); } 
  get bloodgroup() { return this.secondForm.get('bloodgroup'); } 
  get disability() { return this.secondForm.get('disability'); } 

  get country() { return this.thirdForm.get('country'); } 
  get state() { return this.thirdForm.get('state'); } 
  get city() { return this.thirdForm.get('city'); } 
  get taluka() { return this.thirdForm.get('taluka'); } 
  get highesteducation() { return this.thirdForm.get('highesteducation'); } 
  get employeein() { return this.thirdForm.get('employeein'); } 
  get occupation() { return this.thirdForm.get('occupation'); } 
  get annualincome() { return this.thirdForm.get('annualincome'); } 
  get jobcountry() { return this.thirdForm.get('jobcountry'); } 
  get jobcity() { return this.thirdForm.get('jobcity'); } 
  get fathername() { return this.thirdForm.get('fathername'); }
  get fatheroccupation() { return this.thirdForm.get('fatheroccupation'); }
  get mothername() { return this.thirdForm.get('mothername'); }
  get motheroccupation() { return this.thirdForm.get('motheroccupation'); }
  get gaurdiancontact() { return this.thirdForm.get('gaurdiancontact'); }
  get familystatus() { return this.thirdForm.get('familystatus'); } 
  get familytype() { return this.thirdForm.get('familytype'); } 
  get familyvalue() { return this.thirdForm.get('familyvalue'); } 


  get profilePhoto() { return this.fourForm.get('profilePhoto'); } 
  get fewlineyou() { return this.fourForm.get('fewlineyou'); } 
  get fewlinepartner() { return this.fourForm.get('fewlinepartner'); } 

  ngAfterViewInit()
  {
     if(this.createprofileval == "Son" || this.createprofileval == "Brother")
            {
                $("#male").attr('disabled', false);
                $("#female").attr('disabled', true);
                this.defaultDate('Male')
            }
            else if(this.createprofileval == "Sister" || this.createprofileval == "Daughter")
            {
                $("#female").attr('disabled', false);
                $("#male").attr('disabled', true);
                this.defaultDate('Female');
            }
            else
            {
                $("#male").attr('disabled', false);
                $("#female").attr('disabled', false);
                this.defaultDate('Female');
            }
  }

  showfirstscreen()
  {
    this.opensweetalertcst();
  }

  showsecondscreen()
  {
    this.backtosecondscreen();
  }

  showthirdscreen()
  {
    this.backtothirdscreen();
  }

  appdetails()
  {
      let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('CasteID', '5');

          this.service.appversionUrl(body,headers).subscribe(Result =>{
            console.log(Result)
          if(Result['status']==1) 
          {   
            localStorage.setItem('AppMaleLimit',Result['data'][0].AppMaleLimit); 
            localStorage.setItem('AppFemaleLimit',Result['data'][0].AppFemaleLimit); 
          } 


        })
  }

basicinfoPost(userdata: HTMLInputElement) 
{
    this.isSubmitted = true;
    if(!this.basicForm.valid) 
    {
      return false;
    } 
    else 
    {
      
        this.logloading = true;
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('ChkEmailID', userdata['emailid']);
        body.append('ChkContactNo', userdata['emailid']);
        this.regservice.userExistUrl(body,headers).subscribe(Response =>
        {

            if(userdata['gender']=='Female' || userdata['gender']=='female')
            {
              this.imageUrl = this.masterUrl+'DefaultAvatar/Female/default_female.png';
            }
            else
            {
              this.imageUrl = this.masterUrl+'DefaultAvatar/Male/default_male.png';
            }


          if(Response['status']==1) 
          {
            //this.logloading = true;
            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();
            body.append('ChkEmailID', userdata['phoneno']);
            body.append('ChkContactNo', userdata['phoneno']);
            this.regservice.userExistUrl(body,headers).subscribe(Response =>
            {
                if(Response['status']==1) 
                { 
                    localStorage.setItem("createProfile", userdata['createProfile']);
                    localStorage.setItem("fullname", userdata['fullname']);
                    localStorage.setItem("gender", userdata['gender']);
                    localStorage.setItem("birthdate", this.convertlocaldate(userdata['birthdate']));
                    localStorage.setItem("religion", userdata['religion']);
                    localStorage.setItem("motherTongue", userdata['motherTongue']);
                    localStorage.setItem("emailid", userdata['emailid']);
                    localStorage.setItem("phoneno", userdata['phoneno']);
                    localStorage.setItem("password", userdata['password']);

                    this.createprofileval = localStorage.getItem("createProfile");
                    this.fullnameval = localStorage.getItem("fullname");
                    this.genderval = localStorage.getItem("gender");
                    if(localStorage.getItem("birthdate")===null)
                    {
                      this.birthnameval ="";
                    }
                    else
                    {
                        this.birthnamevalformat = localStorage.getItem("birthdate").split("-");
                        this.birthnameval = new Date(this.birthnamevalformat[2], this.birthnamevalformat[1]-1, this.birthnamevalformat[0])
                    }

                    this.religionval = localStorage.getItem("religion");
                    this.motherTongueval = localStorage.getItem("motherTongue");
                    this.emailidval = localStorage.getItem("emailid");
                    this.phonenoval = localStorage.getItem("phoneno");
                    this.passwordval = localStorage.getItem("password");

                    if(this.genderval == "male" || this.genderval == "Male")
                    {
                      this.MaritalStatusArray=[
                        {mstatus : "Never Married",mstatusval : "Never Married"},
                        {mstatus :"Divorced" ,mstatusval : "Divorced"},
                        {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
                        {mstatus :"Widow" ,mstatusval : "Widow"} 
                      ];
                    }
                    else
                    {
                      this.MaritalStatusArray=[
                        {mstatus : "Never Married",mstatusval : "Never Married"},
                        {mstatus :"Divorced" ,mstatusval : "Divorced"},
                        {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
                        {mstatus :"Widower" ,mstatusval : "Widower"}    
                      ];
                    }
           
                  this.logloading = false;
                  $(document).ready(function()
                  { 
                    
                      $('#progressbar li:nth-child(2)').addClass("active");         
                      $("#first").hide();
                      $("#second").show();
                      $("#third").hide();
                      $("#four").hide();
                  })
                  
                      if(localStorage.getItem("maritalstatus")!="Never Married" && localStorage.getItem("maritalstatus")!="" && localStorage.getItem("maritalstatus")!=null)
                      {
                         this.isShown = true;
                         this.secondForm.get('noChildren').setValidators(Validators.required);
                         this.secondForm.get('noChildren').updateValueAndValidity();
                         this.noChildrenval = localStorage.getItem("noChildren");
                         if(this.noChildrenval!=0)
                         {
                           this.isChild = true;
                           this.secondForm.get('childrenStaying').setValidators(Validators.required);
                           this.secondForm.get('childrenStaying').updateValueAndValidity();
                         }
                         else
                         {
                           this.isChild = false;
                           this.secondForm.get('childrenStaying').clearValidators();
                           this.secondForm.get('childrenStaying').updateValueAndValidity();
                         }
                      }
                      else
                      {
                        this.isShown =false;
                        this.isChild = false;
                        this.secondForm.get('noChildren').clearValidators();
                        this.secondForm.get('noChildren').updateValueAndValidity();
                      }

                      if(localStorage.getItem("caste")!=null && localStorage.getItem("caste")!="")
                      {
                          
                      }
                      else
                      {
                          this.getCasteDetails();
                      }
                      
                } 
                else
                {
                  this.opensweetalertdng('Your phone number is already exist. Please try with new one!');
                  this.logloading = false;
                  $(document).ready(function()
                    { 
                          $('#progressbar li:nth-child(1)').addClass("active");
                          $('#progressbar li:nth-child(2)').removeClass("active");
                          $('#progressbar li:nth-child(3)').removeClass("active");
                          $('#progressbar li:nth-child(4)').removeClass("active");         
                          $("#first").show();
                          $("#second").hide();
                          $("#third").hide();
                          $("#four").hide();
                    })
                 }    
            },
        error => {
                    Swal.fire('Oops...', "Please check your internet connection.", 'info');
                    this.logloading = false;
                })
        } 
        else
        {
              this.opensweetalertdng('Your email address is already exist. Please try with new one!');
              this.logloading = false;
              $(document).ready(function()
              { 
                  $('#progressbar li:nth-child(1)').addClass("active");
                  $('#progressbar li:nth-child(2)').removeClass("active");
                  $('#progressbar li:nth-child(3)').removeClass("active");
                  $('#progressbar li:nth-child(4)').removeClass("active");         
                  $("#first").show();
                  $("#second").hide();
                  $("#third").hide();
                  $("#four").hide();
              })
        }
  
      },
        error => {
                    Swal.fire('Oops...', "Please check your internet connection.", 'info');
                    this.logloading = false;
                })        
    } 

    //alert(this.gothraval)
    
}

secondinfoPost(userdata: HTMLInputElement) 
{
    this.isSubmitted1 = true;
    this.logloading = true;
    if(!this.secondForm.valid) 
    {
        this.logloading = false;
        return false;
    } 
    else 
    {
        localStorage.setItem("caste", userdata['caste']);
        localStorage.setItem("subcaste", userdata['subcaste']);
        if(userdata['gothra']!==null)
        {
          localStorage.setItem("gothra", userdata['gothra']); 
        }
        else
        {
          localStorage.setItem("gothra",'');  
        }

        localStorage.setItem("dosh", userdata['dosh']);
        localStorage.setItem("maritalstatus", userdata['maritalstatus']);
        if(userdata['maritalstatus']=="Never Married")
        {
            localStorage.setItem("noChildren", '');
            localStorage.setItem("childrenStaying", '');
        }
        else
        {
            localStorage.setItem("noChildren", userdata['noChildren']);
            localStorage.setItem("childrenStaying", userdata['childrenStaying']);
        }
        
        localStorage.setItem("candidateheight", userdata['candidateheight']);

        if(userdata['birthtime']!==null)
        {
          localStorage.setItem("birthtime", userdata['birthtime']); 
        }
        else
        {
          localStorage.setItem("birthtime",''); 
        }

        if(userdata['birthplace']!==null)
        {
          localStorage.setItem("birthplace", userdata['birthplace']); 
        }
        else
        {
          localStorage.setItem("birthplace",'');
        }
        

        if(userdata['gothra']!==null)
        {
          localStorage.setItem("complexion", userdata['complexion']);
        }
        else
        {
          localStorage.setItem("complexion",'');  
        }


        
        
        if(userdata['bloodgroup']!==null)
        {
          localStorage.setItem("bloodgroup", userdata['bloodgroup']); 
        }
        else
        {
          localStorage.setItem("bloodgroup",''); 
        }
        

        localStorage.setItem("disability", userdata['disability']);

        this.casteval = localStorage.getItem("caste");
        this.subcasteval = localStorage.getItem("subcaste");
        if(localStorage.getItem("gothra")!==null && localStorage.getItem("gothra")!='')
        {
          this.gothraval = localStorage.getItem("gothra"); 
        }
        else
        {
            this.gothraval = null;
        }
        this.doshval = localStorage.getItem("dosh");
        this.maritalstatusval = localStorage.getItem("maritalstatus");
        this.candidateheightval = localStorage.getItem("candidateheight");
        this.birthtimeval = localStorage.getItem("birthtime");
        this.birthplaceval = localStorage.getItem("birthplace");
        if(localStorage.getItem("complexion")!==null && localStorage.getItem("complexion")!='')
        {
          this.complexionval = localStorage.getItem("complexion");
        }
        else
        {
            this.complexionval = null;
        }

        if(localStorage.getItem("bloodgroup")!==null && localStorage.getItem("bloodgroup")!='')
        {
          this.bloodgroupval = localStorage.getItem("bloodgroup");
        }
        else
        {
            this.bloodgroupval = null;
        }
        
        this.disabilityval = localStorage.getItem("disability");
        this.noChildrenval = localStorage.getItem("noChildren");
        this.childrenStayingval = localStorage.getItem("childrenStaying");
        this.logloading = false;
        $(document).ready(function()
        { 
          
            $('#progressbar li:nth-child(2)').addClass("active");   
            $('#progressbar li:nth-child(3)').addClass("active");         
            $("#first").hide();
            $("#second").hide();
            $("#third").show();
            $("#four").hide();
            
        })
        
      } 
}



thirdPost(userdata: HTMLInputElement) 
{
    this.logloading = true;
    this.isSubmitted2 = true;
    if(!this.thirdForm.valid) 
    {
        this.logloading = false;
        return false;
    } 
    else 
    {
        localStorage.setItem("country", userdata['country']);
        localStorage.setItem("state", userdata['state']);
        localStorage.setItem("city", userdata['city']);

        if(userdata['taluka']!==null)
        {
          localStorage.setItem("taluka", userdata['taluka']); 
        }
        else
        {
          localStorage.setItem("taluka",'');
        }


        localStorage.setItem("highesteducation", userdata['highesteducation']);      

        if(userdata['othereducation']!==null)
        {
          localStorage.setItem("othereducation", userdata['othereducation']); 
        }
        else
        {
          localStorage.setItem("othereducation",'');
        }


        localStorage.setItem("employeein", userdata['employeein']);
        localStorage.setItem("occupation", userdata['occupation']);

        if(userdata['annualincome']!==null)
        {
          localStorage.setItem("annualincome", userdata['annualincome']); 
        }
        else
        {
          localStorage.setItem("annualincome",'');
        }

        if(userdata['jobcountry']!==null)
        {
          localStorage.setItem("jobcountry", userdata['jobcountry']); 
        }
        else
        {
          localStorage.setItem("jobcountry",'');
        }

        
        
        if(userdata['jobcity']!==null)
        {
          localStorage.setItem("jobcity", userdata['jobcity']); 
        }
        else
        {
          localStorage.setItem("jobcity",'');
        }



        localStorage.setItem("fatheroccupation", userdata['fatheroccupation']);
        localStorage.setItem("fathername", userdata['fathername']);
        localStorage.setItem("mothername", userdata['mothername']);
        localStorage.setItem("motheroccupation", userdata['motheroccupation']);
        localStorage.setItem("gaurdiancontact", userdata['gaurdiancontact']);
        localStorage.setItem("familystatus", userdata['familystatus']);
        localStorage.setItem("familytype", userdata['familytype']);
        localStorage.setItem("familyvalue", userdata['familyvalue']);

        this.countryval = localStorage.getItem("country");
        this.stateval = localStorage.getItem("state");
        this.cityval = localStorage.getItem("city");
        this.talukaval = localStorage.getItem("taluka");
        this.highesteducationaval = localStorage.getItem("highesteducation");      
        this.othereducationval = localStorage.getItem("othereducation");
        this.employeeinval = localStorage.getItem("employeein");
        this.occupationval = localStorage.getItem("occupation");
        this.annualincomeval = localStorage.getItem("annualincome");

        

        if(localStorage.getItem("jobcountry")!==null)
        {
          this.jobcountryval = localStorage.getItem("jobcountry"); 
        }
        else
        {
          this.jobcountryval = null;
        }

        this.jobcityval = localStorage.getItem("jobcity");
        this.fatheroccupationval = localStorage.getItem("fatheroccupation");
        this.fathernameval = localStorage.getItem("fathername");
        this.mothernameval = localStorage.getItem("mothername");
        this.motheroccupationval = localStorage.getItem("motheroccupation");
        this.gaurdiancontactval = localStorage.getItem("gaurdiancontact");
        this.familystatusval = localStorage.getItem("familystatus");
        this.familytypeval = localStorage.getItem("familytype");
        this.familyvalueval = localStorage.getItem("familyvalue");

      if(localStorage.getItem("createProfile")==="Myself")
      {
          this.createProfiledes=" ";
          this.createProfilenoun="I am ";
          this.candidatenoun="I have ";
          this.createfor1="my ";
          this.createfor2=" I ";
          this.AboutProfile="About Myself";
      }
      else if(localStorage.getItem("createProfile")==="Son")
      {
          this.createProfiledes=" Son's ";
          this.createProfilenoun="he is ";
          this.candidatenoun="He has ";
          this.createfor1="his ";
          this.createfor2=" He ";
          this.AboutProfile="About My Son";
      }
      else if(localStorage.getItem("createProfile")==="Daughter")
      {
          this.createProfiledes=" Daughter's ";
          this.createProfilenoun="she is ";
          this.candidatenoun="She has ";
          this.createfor1="her ";
          this.createfor2=" She ";
          this.AboutProfile="About My Daughter";
      }
      else if(localStorage.getItem("createProfile")==="Brother")
      {
          this.createProfiledes=" Brother's ";
          this.createProfilenoun="he is ";
          this.candidatenoun="He has ";
          this.createfor1="his ";
          this.createfor2=" He ";
          this.AboutProfile="About My Brother";
      }
      else if(localStorage.getItem("createProfile")==="Sister")
      {
          this.createProfiledes=" Sister's ";
          this.createProfilenoun="she is ";
          this.candidatenoun="She has ";
          this.createfor1="her ";
          this.createfor2=" She ";
          this.AboutProfile="About My Sister";
      }
      else if(localStorage.getItem("createProfile")==="Relative")
      {
          this.createProfiledes=" Relative's ";
          this.AboutProfile="About My Relative";
          if(localStorage.getItem("gender")==="male" || localStorage.getItem("gender")==="Male")
          {
            this.createProfilenoun="he is ";
            this.candidatenoun="He has ";
            this.createfor1="his ";
            this.createfor2=" He ";
          }
          else
          {
            this.createProfilenoun="she is ";
            this.candidatenoun="She has ";
            this.createfor1="her ";
            this.createfor2=" She ";
          }
      }
      else if(localStorage.getItem("createProfile")==="Friend")
      {
          this.createProfiledes=" Friend's ";
          this.AboutProfile="About My Friend";
          if(localStorage.getItem("gender")==="male" || localStorage.getItem("gender")==="Male")
          {
            this.createProfilenoun="he is ";
            this.candidatenoun="He has ";
            this.createfor1="his ";
            this.createfor2=" He ";
          }
          else
          {
            this.createProfilenoun="she is ";
            this.candidatenoun="She has ";
            this.createfor1="her ";
            this.createfor2=" She ";
          }
      }

        if(localStorage.getItem("occupation")===null)
          {
           this.occupationdesc =''; 
          }
          else
          {
            this.occupationdesc = localStorage.getItem("occupation").split('|||'); 
          }

          if(localStorage.getItem("employeein")===null)
          {
            this.employeeindesc = '';
          }
          else
          {
            this.employeeindesc = localStorage.getItem("employeein").split('|||');
          }

          if(localStorage.getItem("highesteducation")===null)
          {
            this.candiatehighedu ='';
          }
          else
          {
            this.candiatehighedu = localStorage.getItem("highesteducation").split('|||'); 
          }



      this.aboutusdescriptionval="My"+this.createProfiledes+"name is "+localStorage.getItem("fullname")+" and "+this.createProfilenoun+"working as "+this.occupationdesc[1]+ " in the "+this.employeeindesc[0]+". "+this.candidatenoun+"completed "+this.createfor1+""+this.candiatehighedu[1]+""+this.createfor2+"grew up in "+localStorage.getItem("familystatus")+",  "+localStorage.getItem("familytype")+" with "+localStorage.getItem("familyvalue")+".";

      this.fourForm.get('fewlineyou').patchValue(this.aboutusdescriptionval);

        this.logloading = false;
        $(document).ready(function()
        { 
            $('#progressbar li:nth-child(2)').addClass("active");   
            $('#progressbar li:nth-child(3)').addClass("active");
            $('#progressbar li:nth-child(4)').addClass("active");         
            $("#first").hide();
            $("#second").hide();
            $("#third").hide();
            $("#four").show();
        })
        
      } 
}


fourPost(userdata: HTMLInputElement)
{

    this.logloading = true;
    if(!this.fourForm.valid) 
    {
        this.logloading = false;
        return false;
    } 
    else 
    {

      if(this.hiddenFileValue==0)
      {
        this.logloading = false;

        Swal.fire({
          title: this.sitetitle,
          text: "Do you want to upload your profile photo?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => 
        {
            if (result.value) 
            {
                $(document).ready(function()
                { 
                      $('#progressbar li:nth-child(1)').addClass("active");
                      $('#progressbar li:nth-child(2)').addClass("active");
                      $('#progressbar li:nth-child(3)').addClass("active");
                      $('#progressbar li:nth-child(4)').addClass("active");         
                      $("#first").hide();
                      $("#second").hide();
                      $("#third").hide();
                      $("#four").show();
                })
            } 
            else if (result.dismiss === Swal.DismissReason.cancel) 
            {


      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      this.casteval = localStorage.getItem("caste").split('|||');
      this.religionval = localStorage.getItem("religion").split('|||');
      this.countryIdval = localStorage.getItem("country").split('|||');
      this.stateIdval = localStorage.getItem("state").split('|||');
      this.educationpostval = localStorage.getItem("highesteducation").split('|||');
      this.employeeInIdval = localStorage.getItem("employeein").split('|||');
      this.OccupationIdval = localStorage.getItem("occupation").split('|||');
      this.heightIdval = localStorage.getItem("candidateheight").split('|||');
      

      if(localStorage.getItem("bloodgroup")===null)
          {
            this.postbloodvalformat ="";
          }
          else
          {
            this.postbloodvalformat = localStorage.getItem("bloodgroup").split("|||");
          }

          if(localStorage.getItem("disability")=="Yes")
          {
              this.postbloodval = "Physical Disability";
          }
          else
          {
              this.postbloodval = "Normal";
          }

      body.append('MembCasteCode', this.casteval[1]);
      body.append('MembIMEINo', '');
      body.append('MembProfilefor', localStorage.getItem("createProfile"));
      body.append('Membfullname', localStorage.getItem("fullname"));
      body.append('MembGender', localStorage.getItem("gender"));
      body.append('MembDOB', localStorage.getItem("birthdate"));
      body.append('MembRelgID', this.religionval[0]);
      body.append('MembReligion', this.religionval[1]);
      body.append('MembCasteID', this.casteval[0]);
      body.append('MembCaste', this.casteval[2]);
      body.append('MembSubCastid', localStorage.getItem("subcaste"));
      body.append('MembMotherTongue', localStorage.getItem("motherTongue"));
      body.append('Membemailid', localStorage.getItem("emailid"));
      body.append('Membcontactno', localStorage.getItem("phoneno"));
      body.append('MembPassword', localStorage.getItem("password"));
      body.append('MembDetGothra', localStorage.getItem("gothra"));
      body.append('MembDetMartial', localStorage.getItem("maritalstatus"));
      body.append('MembNoofChild', localStorage.getItem("noChildren"));
      body.append('MembChildStay', localStorage.getItem("childrenStaying"));
      body.append('MembDetHeight', this.heightIdval[0]);
      body.append('MembdetHeightCode', this.heightIdval[1]);
      body.append('MembDetBirthTime', localStorage.getItem("birthtime"));
      body.append('MembdetBirthPlace', localStorage.getItem("birthplace"));
      body.append('MembDetComplextion', localStorage.getItem("complexion"));
      body.append('membDetBloodCode', this.postbloodvalformat[1]);
      body.append('membDetBloodGrp', this.postbloodvalformat[0]);
      body.append('MembDetDisability', this.postbloodval);
      body.append('MembDetCountryID', this.countryIdval[0]);
      body.append('MembCountryNm', this.countryIdval[1]);
      body.append('MembDetStateID', this.stateIdval[0]);
      body.append('MembStateNm', this.stateIdval[1]);
      body.append('MembDetCityID', localStorage.getItem("city"));
      body.append('MembDetVillage', localStorage.getItem("taluka"));
      body.append('MembdetEduID', this.educationpostval[0]);
      body.append('MembDetEducation', this.educationpostval[1]);
      body.append('MembdetEduGroupID', this.educationpostval[2]);
      body.append('MembdetEduGroupName', this.educationpostval[3]);
      body.append('MembOtherEdu',localStorage.getItem("othereducation"))
      body.append('MembDetEmpIn', this.employeeInIdval[0]);
      body.append('MembdetOccupID', this.OccupationIdval[0]);
      body.append('MembDetOccup', this.OccupationIdval[1]);
      body.append('MembdetOccupGroupID', this.OccupationIdval[2]);
      body.append('MembdetOccupGroupName', this.OccupationIdval[3]);
      body.append('MembDetIncome', localStorage.getItem("annualincome"));
      body.append('MembDetJobCountryID', localStorage.getItem("jobcountry"));
      body.append('MembDetJobCity', localStorage.getItem("jobcity"));
      body.append('MembDetFamilyStatus', localStorage.getItem("familystatus"));
      body.append('MembDetFamilyType', localStorage.getItem("familytype"));
      body.append('MembDetFamilyValue', localStorage.getItem("familyvalue"));
      body.append('MembDetAboutUS', userdata['fewlineyou']);
      body.append('MembAboutPartner', userdata['fewlinepartner']);
      body.append('MembIntCastFlg', '0');
      body.append('MembDeviceToken', '');
      body.append('MembDetFatherName', localStorage.getItem("fathername"));
      body.append('MembDetFatherOccp', localStorage.getItem("fatheroccupation"));
      body.append('MembDetFContactno', localStorage.getItem("gaurdiancontact"));
      body.append('MembDetMotherName', localStorage.getItem("mothername"));
      body.append('MembDetMotherOccp', localStorage.getItem("motheroccupation"));
      body.append('MembRefCode', '');
      body.append('MemberIsPhotoUpload', this.hiddenFileValue);
      body.append('Membprofilephoto', '');
      //body.append('Membprofilephoto', this.fileToUpload,this.fileToUpload.name);
      

      this.regservice.registrationUrl(body,headers).subscribe(Finalresult =>{
          console.log(Finalresult['status'])
      if(Finalresult['status']==1) 
      {
          localStorage.clear();
          const restuls = Finalresult['data'];
          localStorage.setItem("userid", restuls.MemberID);
          localStorage.setItem("userprofileid", restuls.MembProfileId);
          localStorage.setItem("screen",'Registration');
          this.logloading = false;
          let link = ['/confirm-otp'];
          this.route.navigate(link);
      } 
      else 
      {
          this.opensweetalertdng(Finalresult['message']);
          localStorage.removeItem("userid");
          localStorage.removeItem("userfullname");
          this.logloading = false;
      }

      },
      error => {
        
        $(document).ready(function()
                { 
                      $('#progressbar li:nth-child(1)').addClass("active");
                      $('#progressbar li:nth-child(2)').addClass("active");
                      $('#progressbar li:nth-child(3)').addClass("active");
                      $('#progressbar li:nth-child(4)').addClass("active");         
                      $("#first").hide();
                      $("#second").hide();
                      $("#third").hide();
                      $("#four").show();
                })

        this.logloading = false;        
        Swal.fire('Oops...', "Please check your internet connection.", 'info');
      })


              
            }
        })
  
    
      }
      else
      {
        // let checkimg = this.fourForm.get('profilePhoto').value['name'];
        // alert(checkimg)
        // return false;

      
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      this.casteval = localStorage.getItem("caste").split('|||');
      this.religionval = localStorage.getItem("religion").split('|||');
      this.countryIdval = localStorage.getItem("country").split('|||');
      this.stateIdval = localStorage.getItem("state").split('|||');
      this.educationpostval = localStorage.getItem("highesteducation").split('|||');
      this.employeeInIdval = localStorage.getItem("employeein").split('|||');
      this.OccupationIdval = localStorage.getItem("occupation").split('|||');
      this.heightIdval = localStorage.getItem("candidateheight").split('|||');
      

      if(localStorage.getItem("bloodgroup")===null)
          {
            this.postbloodvalformat ="";
          }
          else
          {
            this.postbloodvalformat = localStorage.getItem("bloodgroup").split("|||");
          }

          if(localStorage.getItem("disability")=="Yes")
          {
              this.postbloodval = "Physical Disability";
          }
          else
          {
              this.postbloodval = "Normal";
          }

      body.append('MembCasteCode', this.casteval[1]);
      body.append('MembIMEINo', '');
      body.append('MembProfilefor', localStorage.getItem("createProfile"));
      body.append('Membfullname', localStorage.getItem("fullname"));
      body.append('MembGender', localStorage.getItem("gender"));
      body.append('MembDOB', localStorage.getItem("birthdate"));
      body.append('MembRelgID', this.religionval[0]);
      body.append('MembReligion', this.religionval[1]);
      body.append('MembCasteID', this.casteval[0]);
      body.append('MembCaste', this.casteval[2]);
      body.append('MembSubCastid', localStorage.getItem("subcaste"));
      body.append('MembMotherTongue', localStorage.getItem("motherTongue"));
      body.append('Membemailid', localStorage.getItem("emailid"));
      body.append('Membcontactno', localStorage.getItem("phoneno"));
      body.append('MembPassword', localStorage.getItem("password"));
      body.append('MembDetGothra', localStorage.getItem("gothra"));
      body.append('MembDetMartial', localStorage.getItem("maritalstatus"));
      body.append('MembNoofChild', localStorage.getItem("noChildren"));
      body.append('MembChildStay', localStorage.getItem("childrenStaying"));
      body.append('MembDetHeight', this.heightIdval[0]);
      body.append('MembdetHeightCode', this.heightIdval[1]);
      body.append('MembDetBirthTime', localStorage.getItem("birthtime"));
      body.append('MembdetBirthPlace', localStorage.getItem("birthplace"));
      body.append('MembDetComplextion', localStorage.getItem("complexion"));
      body.append('membDetBloodCode', this.postbloodvalformat[1]);
      body.append('membDetBloodGrp', this.postbloodvalformat[0]);
      body.append('MembDetDisability', this.postbloodval);
      body.append('MembDetCountryID', this.countryIdval[0]);
      body.append('MembCountryNm', this.countryIdval[1]);
      body.append('MembDetStateID', this.stateIdval[0]);
      body.append('MembStateNm', this.stateIdval[1]);
      body.append('MembDetCityID', localStorage.getItem("city"));
      body.append('MembDetVillage', localStorage.getItem("taluka"));
      body.append('MembdetEduID', this.educationpostval[0]);
      body.append('MembDetEducation', this.educationpostval[1]);
      body.append('MembdetEduGroupID', this.educationpostval[2]);
      body.append('MembdetEduGroupName', this.educationpostval[3]);
      body.append('MembOtherEdu',localStorage.getItem("othereducation"))
      body.append('MembDetEmpIn', this.employeeInIdval[0]);
      body.append('MembdetOccupID', this.OccupationIdval[0]);
      body.append('MembDetOccup', this.OccupationIdval[1]);
      body.append('MembdetOccupGroupID', this.OccupationIdval[2]);
      body.append('MembdetOccupGroupName', this.OccupationIdval[3]);
      body.append('MembDetIncome', localStorage.getItem("annualincome"));
      body.append('MembDetJobCountryID', localStorage.getItem("jobcountry"));
      body.append('MembDetJobCity', localStorage.getItem("jobcity"));
      body.append('MembDetFamilyStatus', localStorage.getItem("familystatus"));
      body.append('MembDetFamilyType', localStorage.getItem("familytype"));
      body.append('MembDetFamilyValue', localStorage.getItem("familyvalue"));
      body.append('MembDetAboutUS', userdata['fewlineyou']);
      body.append('MembAboutPartner', userdata['fewlinepartner']);
      body.append('MembIntCastFlg', '0');
      body.append('MembDeviceToken', '');
      body.append('MembDetFatherName', localStorage.getItem("fathername"));
      body.append('MembDetFatherOccp', localStorage.getItem("fatheroccupation"));
      body.append('MembDetFContactno', localStorage.getItem("gaurdiancontact"));
      body.append('MembDetMotherName', localStorage.getItem("mothername"));
      body.append('MembDetMotherOccp', localStorage.getItem("motheroccupation"));
      body.append('MembRefCode', '');
      body.append('MemberIsPhotoUpload', this.hiddenFileValue);
      body.append('Membprofilephoto', this.fourForm.get('profilePhoto').value);
      

      this.regservice.registrationUrl(body,headers).subscribe(Finalresult =>{
          console.log(Finalresult['status'])
          this.logloading = false;
      if(Finalresult['status']==1) 
      {
        localStorage.clear();
        const restuls = Finalresult['data'];
          localStorage.setItem("userid", restuls.MemberID);
          localStorage.setItem("userprofileid", restuls.MembProfileId);
          localStorage.setItem("screen",'Registration');
          this.logloading = false;
          let link = ['/confirm-otp'];
          this.route.navigate(link);
      } 
      else 
      {
          this.opensweetalertdng(Finalresult['message']);
          localStorage.removeItem("userid");
          localStorage.removeItem("userfullname");
          this.logloading = false;
      }

      },
      error => {
        Swal.fire('Oops...', "Please check your internet connection.", 'info');
        this.logloading = false;
        $(document).ready(function()
                { 
                      $('#progressbar li:nth-child(1)').addClass("active");
                      $('#progressbar li:nth-child(2)').addClass("active");
                      $('#progressbar li:nth-child(3)').addClass("active");
                      $('#progressbar li:nth-child(4)').addClass("active");         
                      $("#first").hide();
                      $("#second").hide();
                      $("#third").hide();
                      $("#four").show();
                })
      })

        }

    }
}

getMaritalStatus(e)
{
  if(e.target.value == "Divorced" || e.target.value == "Awaiting Divorced" || e.target.value == "Widow" || e.target.value == "Widower")
  {
    this.isShown = true;
    this.secondForm.get('noChildren').setValidators(Validators.required);
  }
  else
  {
    this.isShown =false;
    this.isChild = false;
    this.secondForm.get('noChildren').clearValidators();
  }
  
  this.secondForm.get('noChildren').updateValueAndValidity();
}

getNoOfChildren(e)
{
  if(e.target.value == "0")
  {
    this.isChild = false;
    this.secondForm.get('childrenStaying').clearValidators();
  }
  else
  {
    this.isChild = true;
    this.secondForm.get('childrenStaying').setValidators(Validators.required);
  }

  this.secondForm.get('childrenStaying').updateValueAndValidity();
}

onDateChange(newDate: Date) {
    this.previousDate = new Date(newDate);
  }

  onChangeEducation(e)
  {
      //alert(e.target.value)
  }

  onChangeOccupation(e)
  {
    this.logspinner = true;
    this.employeeInId = e.target.value.split('|||');
    if(this.employeeInId[1])
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('occID', this.employeeInId[1]);
      this.regservice.getOccupationData(body,headers).subscribe(Response =>
        {
            console.log("Occupation"+Response)
            this.logspinner = false; 
            this.Occupationlist =  Response['data'];
        })

        if(this.employeeInId[0]!="Not Working")
        {
        
        if(this.thirdForm.value.annualincome=='' || this.thirdForm.value.annualincome==null)
          {
              this.isShown1 = true;
              this.thirdForm.get('annualincome').setValidators([Validators.required]); 
              this.thirdForm.get('annualincome').updateValueAndValidity();
          }
          else
          {
              this.isShown1 = false;
              this.thirdForm.get('annualincome').clearValidators(); 
              this.thirdForm.get('annualincome').updateValueAndValidity();
          }


          if(this.thirdForm.value.jobcountry=='' || this.thirdForm.value.jobcountry==null)
          {
              this.isShown2 = true;
              this.thirdForm.get('jobcountry').setValidators([Validators.required]); 
              this.thirdForm.get('jobcountry').updateValueAndValidity();
          }
          else
          {
              this.isShown2 = false;
              this.thirdForm.get('jobcountry').clearValidators(); 
              this.thirdForm.get('jobcountry').updateValueAndValidity();
          }


          if(this.thirdForm.value.jobcity=='' || this.thirdForm.value.jobcity==null)
          {
              this.isShown3 = true;
              this.thirdForm.get('jobcity').setValidators([Validators.required]); 
              this.thirdForm.get('jobcity').updateValueAndValidity();
          }
          else
          {
              this.isShown3 = false;
              this.thirdForm.get('jobcity').clearValidators(); 
              this.thirdForm.get('jobcity').updateValueAndValidity();
          }


        }
        else
        {
            this.thirdForm.get('annualincome').clearValidators(); 
            this.thirdForm.get('annualincome').updateValueAndValidity();

            this.thirdForm.get('jobcountry').clearValidators(); 
            this.thirdForm.get('jobcountry').updateValueAndValidity();

            this.thirdForm.get('jobcity').clearValidators(); 
            this.thirdForm.get('jobcity').updateValueAndValidity();
        }


    }
    else
    {
      this.employeeInId = e.target.value;
      this.Occupationlist = [];
      this.logspinner = false;
      this.thirdForm.get('annualincome').clearValidators(); 
      this.thirdForm.get('annualincome').updateValueAndValidity();

      this.thirdForm.get('jobcountry').clearValidators(); 
      this.thirdForm.get('jobcountry').updateValueAndValidity();

      this.thirdForm.get('jobcity').clearValidators(); 
      this.thirdForm.get('jobcity').updateValueAndValidity();
    }

  }


  onSelectOccupation(employeeInId)
  {
    this.logspinner = true;
    this.employeeInId = employeeInId.split('|||');
    if(this.employeeInId[1])
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('occID', this.employeeInId[1]);
      this.regservice.getOccupationData(body,headers).subscribe(Response =>
        {
            console.log("Occupation"+Response)
            this.logspinner = false; 
            this.Occupationlist =  Response['data'];
        })
    }
    else
    {
      this.Occupationlist = [];
      this.logspinner = false;
    }

  }

  onChangeProfile(e)
{
     this.profileIdFor = e.target.value; 
    if(this.profileIdFor == "Son" || this.profileIdFor == "Brother")
    {
      $("#male").attr('disabled', false);
      $("#female").attr('disabled', true);
      this.birthdate.reset();
      this.defaultDate('Male')
      this.genderval = "Male";
    }
    else if(this.profileIdFor == "Sister" || this.profileIdFor == "Daughter")
    {
      $("#female").attr('disabled', false);
      $("#male").attr('disabled', true);
      this.birthdate.reset();
      this.defaultDate('Female');
      this.genderval = "Female";
    }
    else
    {
      $("#male").attr('disabled', false);
      $("#female").attr('disabled', false);
      this.genderval = "";
      this.birthdate.reset();
      this.defaultDate('Female');
    }
  
}

getReligionDetails()
{
  this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.regservice.getReligionData(headers).subscribe(Response =>
      {
        this.logspinner = false;
        this.religionlist = Response['data'];
      })
}


getCountryDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getCountryData(headers).subscribe(Response =>
      {
        this.countrylist = Response['data']
      })
}


onChangeCountry(e)
{
    this.logspinner = true;
    this.countryId = e.target.value;
    if(this.countryId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('CountryID', this.countryId);
      this.regservice.getStateData(body,headers).subscribe(Response =>
        {
            this.logspinner = false; 
            this.statelist =  Response['data'];
        })
    }
    else
    {
      this.statelist = [];
      this.logspinner = false;
    }

}



onSelectCountry(countryId)
{
    this.logspinner = true;
    this.countryId = countryId;
    if(this.countryId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('CountryID', this.countryId);
      this.regservice.getStateData(body,headers).subscribe(Response =>
        {
            this.logspinner = false; 
            this.statelist =  Response['data'];
              if(localStorage.getItem("state")!="" && localStorage.getItem("state")!=null)
              {
                  this.onSelectState(localStorage.getItem("state"))
              }
        })
    }
    else
    {
      this.statelist = [];
      this.logspinner = false;
    }

}

onChangeState(e)
{
    this.logspinner = true;
    this.stateId = e.target.value;
    if(this.stateId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('StateID', this.stateId);
      this.regservice.getCityData(body,headers).subscribe(Response =>
        {
            this.logspinner = false;
            this.citylist =  Response['data'];
        })
    }
    else
    {
        this.citylist = [];
        this.logspinner = false;
    }

}



onSelectState(stateId)
{
    this.logspinner = true;
    this.stateId = stateId;
    if(this.stateId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('StateID', this.stateId);
      this.regservice.getCityData(body,headers).subscribe(Response =>
        {
            this.logspinner = false;
            this.citylist =  Response['data'];
        })
    }
    else
    {
        this.citylist = [];
        this.logspinner = false;
    }

}

getEducationDetails()
{

    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.regservice.getEducationData(headers).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.Educationlist = Response['data'];
            console.log(Response['data'])
            
        }
        else
        {
          this.EducationTitle = [];
          this.Educationlist = [];
        }
    })

}


getOccupationDetails()
{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    this.regservice.getOccupationData(headers,body).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.Occupationlist = Response['data'];
            console.log(this.Occupationlist)
        }
        else
        {
          this.Occupationlist = [];
        }
    })

}

getCasteDetails()
{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    this.ReligionID = localStorage.getItem("religion");
    body.append('ReligionID', this.ReligionID);
    this.regservice.getCasteData(body,headers).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.castelist = Response['data'];
        }
        else
        {
          this.castelist = [];
        }
    })
}


  getSubCastDetails(subcastid)
  {
    this.subCastId = subcastid;
    if(this.subCastId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('CasteID', this.subCastId);
      this.regservice.getSubCastData(body,headers).subscribe(Response =>
        {
            this.logspinner = false;
            this.subcastlist =  Response['data'];
        })
    }
    else
    {
      this.subcastlist = [];
      this.logspinner = false;
    }

    
  }

onChangeSubCast(e)
{
    this.logspinner = true;
    this.subCastId = e.target.value;
    if(this.subCastId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('CasteID', this.subCastId);
      this.regservice.getSubCastData(body,headers).subscribe(Response =>
        {
            this.logspinner = false;
            this.subcastlist =  Response['data'];
        })
    }
    else
    {
      this.subcastlist = [];
      this.logspinner = false;
    }

}


getGothraDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getGothraData(headers).subscribe(Response =>
    {
     this.gothralist = Response['data']
    })
}

getHeightDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getHeightData(headers).subscribe(Response =>
    {
      this.heightlist = Response['data']
      console.log(this.heightlist);
    })
}

getBloodGrpDetails()
{
  let headers = new Headers({'Content-Type':'application/json'});
  this.regservice.getBloodGrpData(headers).subscribe(Response =>
  {
    this.bloodgrplist = Response['data']
  })
}


opensweetalertdng(message)
{
   Swal.fire('Oops...', message, 'error')
   this.logloading = false;
}

opensweetalertcst()
{
    Swal.fire({
      title: this.sitetitle,
      text: 'Are you sure you want to go back previous screen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => 
    {
      if (result.value) 
      {
        $(document).ready(function()
        { 
          $('#progressbar li:nth-child(1)').addClass("active");
          $('#progressbar li:nth-child(2)').removeClass("active");
          $('#progressbar li:nth-child(3)').removeClass("active");
          $('#progressbar li:nth-child(4)').removeClass("active");         
          $("#first").show();
          $("#second").hide();
          $("#third").hide();
          $("#four").hide();
        })

          this.createprofileval = localStorage.getItem("createProfile");

            if(this.createprofileval == "Son" || this.createprofileval == "Brother")
            {
                $("#male").attr('disabled', false);
                $("#female").attr('disabled', true);
                this.defaultDate('Male')
            }
            else if(this.createprofileval == "Sister" || this.createprofileval == "Daughter")
            {
                $("#female").attr('disabled', false);
                $("#male").attr('disabled', true);
                this.defaultDate('Female');
            }
            else
            {
                $("#male").attr('disabled', false);
                $("#female").attr('disabled', false);
                this.defaultDate('Female');
            }

          this.fullnameval = localStorage.getItem("fullname");
          if(localStorage.getItem("birthdate")===null)
          {
            this.birthnameval ="";
          }
          else
          {
            this.birthnamevalformat = localStorage.getItem("birthdate").split("-");
            this.birthnameval = new Date(this.birthnamevalformat[2], this.birthnamevalformat[1]-1, this.birthnamevalformat[0])
          }
                    
          this.genderval = localStorage.getItem("gender");
          this.religionval = localStorage.getItem("religion");
          this.motherTongueval = localStorage.getItem("motherTongue");
          this.emailidval = localStorage.getItem("emailid");
          this.phonenoval = localStorage.getItem("phoneno");
          this.passwordval = localStorage.getItem("password");

          this.casteval = localStorage.getItem("caste");
          this.subcasteval = localStorage.getItem("subcaste");
          if(localStorage.getItem("gothra")!==null && localStorage.getItem("gothra")!='')
        {
          this.gothraval = localStorage.getItem("gothra"); 
        }
        else
        {
            this.gothraval = null;
        }
          this.doshval = localStorage.getItem("dosh");
          this.maritalstatusval = localStorage.getItem("maritalstatus");
          this.candidateheightval = localStorage.getItem("candidateheight");
          this.birthtimeval = localStorage.getItem("birthtime");
          this.birthplaceval = localStorage.getItem("birthplace");
          if(localStorage.getItem("complexion")!==null && localStorage.getItem("complexion")!='')
        {
          this.complexionval = localStorage.getItem("complexion");
        }
        else
        {
            this.complexionval = null;
        }
          if(localStorage.getItem("bloodgroup")!==null && localStorage.getItem("bloodgroup")!='')
        {
          this.bloodgroupval = localStorage.getItem("bloodgroup");
        }
        else
        {
            this.bloodgroupval = null;
        }
          this.disabilityval = localStorage.getItem("disability");
          this.noChildrenval = localStorage.getItem("noChildren");
          this.childrenStayingval = localStorage.getItem("childrenStaying");
        
          if(this.genderval == "male" || this.genderval == "Male")
          {
            this.MaritalStatusArray=[
              {mstatus : "Never Married",mstatusval : "Never Married"},
              {mstatus :"Divorced" ,mstatusval : "Divorced"},
              {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
              {mstatus :"Widow" ,mstatusval : "Widow"}
            ];
          }
          else
          {
            this.MaritalStatusArray=[
              {mstatus : "Never Married",mstatusval : "Never Married"},
              {mstatus :"Divorced" ,mstatusval : "Divorced"},
              {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
              {mstatus :"Widower" ,mstatusval : "Widower"}
            ];
        
          }

      } 
      else if (result.dismiss === Swal.DismissReason.cancel) 
      {
          $(document).ready(function()
          { 
              $('#progressbar li:nth-child(1)').addClass("active");
              $('#progressbar li:nth-child(2)').addClass("active");
              $('#progressbar li:nth-child(3)').removeClass("active");
              $('#progressbar li:nth-child(4)').removeClass("active");         
              $("#first").hide();
              $("#second").show();
              $("#third").hide();
              $("#four").hide();
          })
      }
    })
  }

  backtosecondscreen(){
    Swal.fire({
      title: this.sitetitle,
      text: 'Are you sure you want to go back previous screen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => 
    {
      if (result.value) 
      {
        $(document).ready(function()
        { 
          $('#progressbar li:nth-child(1)').addClass("active");
          $('#progressbar li:nth-child(2)').addClass("active");
          $('#progressbar li:nth-child(3)').removeClass("active");
          $('#progressbar li:nth-child(4)').removeClass("active");         
          $("#first").hide();
          $("#second").show();
          $("#third").hide();
          $("#four").hide();
        })
        
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) 
      {
        $(document).ready(function()
        { 
            $('#progressbar li:nth-child(1)').addClass("active");
            $('#progressbar li:nth-child(2)').addClass("active");
            $('#progressbar li:nth-child(3)').addClass("active");
            $('#progressbar li:nth-child(4)').removeClass("active");         
            $("#first").hide();
            $("#second").hide();
            $("#third").show();
            $("#four").hide();
        })
      }
    })
  }


  backtothirdscreen(){
    Swal.fire({
      title: this.sitetitle,
      text: 'Are you sure you want to go back previous screen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => 
    {
      if (result.value) 
      {
        $(document).ready(function()
        { 
          $('#progressbar li:nth-child(1)').addClass("active");
          $('#progressbar li:nth-child(2)').addClass("active");
          $('#progressbar li:nth-child(3)').addClass("active");
          $('#progressbar li:nth-child(4)').removeClass("active");         
          $("#first").hide();
          $("#second").hide();
          $("#third").show();
          $("#four").hide();
        })
        
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) 
      {
        $(document).ready(function()
        { 
            $('#progressbar li:nth-child(1)').addClass("active");
            $('#progressbar li:nth-child(2)').addClass("active");
            $('#progressbar li:nth-child(3)').addClass("active");
            $('#progressbar li:nth-child(4)').addClass("active");         
            $("#first").hide();
            $("#second").hide();
            $("#third").hide();

            if(localStorage.getItem("createProfile")==="Myself")
      {
          this.createProfiledes=" ";
          this.createProfilenoun="I am ";
          this.candidatenoun="I have ";
          this.createfor1="my ";
          this.createfor2="I ";
          this.AboutProfile="About Myself";
      }
      else if(localStorage.getItem("createProfile")==="Son")
      {
          this.createProfiledes=" Son's ";
          this.createProfilenoun="he is ";
          this.candidatenoun="He has ";
          this.createfor1="his ";
          this.createfor2="He ";
          this.AboutProfile="About My Son";
      }
      else if(localStorage.getItem("createProfile")==="Daughter")
      {
          this.createProfiledes=" Daughter's ";
          this.createProfilenoun="she is ";
          this.candidatenoun="She has ";
          this.createfor1="her ";
          this.createfor2="She ";
          this.AboutProfile="About My Daughter";
      }
      else if(localStorage.getItem("createProfile")==="Brother")
      {
          this.createProfiledes=" Brother's ";
          this.createProfilenoun="he is ";
          this.candidatenoun="He has ";
          this.createfor1="his ";
          this.createfor2="He ";
          this.AboutProfile="About My Brother";
      }
      else if(localStorage.getItem("createProfile")==="Sister")
      {
          this.createProfiledes=" Sister's ";
          this.createProfilenoun="she is ";
          this.candidatenoun="She has ";
          this.createfor1="her ";
          this.createfor2="She ";
          this.AboutProfile="About My Sister";
      }
      else if(localStorage.getItem("createProfile")==="Relative")
      {
          this.createProfiledes=" Relative's ";
          this.AboutProfile="About My Relative";
          if(localStorage.getItem("gender")==="male" || localStorage.getItem("gender")==="Male")
          {
            this.createProfilenoun="he is ";
            this.candidatenoun="He has ";
            this.createfor1="his ";
            this.createfor2="He ";
          }
          else
          {
            this.createProfilenoun="she is ";
            this.candidatenoun="She has ";
            this.createfor1="her ";
            this.createfor2="She ";
          }
      }
      else if(localStorage.getItem("createProfile")==="Friend")
      {
          this.createProfiledes=" Friend's ";
          this.AboutProfile="About My Friend";
          if(localStorage.getItem("gender")==="male" || localStorage.getItem("gender")==="Male")
          {
            this.createProfilenoun="he is ";
            this.candidatenoun="He has ";
            this.createfor1="his ";
            this.createfor2="He ";
          }
          else
          {
            this.createProfilenoun="she is ";
            this.candidatenoun="She has ";
            this.createfor1="her ";
            this.createfor2="She ";
          }
      }

        if(localStorage.getItem("occupation")===null)
          {
             this.occupationdesc ='';
          }
          else
          {
            this.occupationdesc = localStorage.getItem("occupation").split('|||');
          }

          if(localStorage.getItem("employeein")===null)
          {
            this.employeeindesc = '';
          }
          else
          {
            this.employeeindesc = localStorage.getItem("employeein").split('|||');
          }

          if(localStorage.getItem("highesteducation")===null)
          {
            this.candiatehighedu =''; 
          }
          else
          {
            this.candiatehighedu = localStorage.getItem("highesteducation").split('|||');
          }



      this.aboutusdescriptionval="My"+this.createProfiledes+"name is "+localStorage.getItem("fullname")+" and "+this.createProfilenoun+"working as "+this.occupationdesc[1]+ " in the "+this.employeeindesc[0]+". "+this.candidatenoun+"completed "+this.createfor1+""+this.candiatehighedu[1]+" "+this.createfor2+"grew up in "+localStorage.getItem("familystatus")+",  "+localStorage.getItem("familytype")+" with "+localStorage.getItem("familyvalue")+".";
      this.fourForm.get('fewlineyou').patchValue(this.aboutusdescriptionval);

            $("#four").show();
        })
      }
    })
  }

    convertdate(str) 
    {
      var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("-");
    }

    convertlocaldate(str) 
    {
      var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [day, mnth, date.getFullYear()].join("-");
    }

    differencedate(gender)
    {
      this.date1 = this.convertdate(new Date()); 
      this.newdate = new Date();
      this.maxDate = new Date();

      if(gender.target.value=="Female")
      {
        //this.pastYear = this.newdate.getFullYear() - 18;
         this.increaseyear = localStorage.getItem('AppFemaleLimit');
         this.pastYear = this.newdate.getFullYear() - this.increaseyear;
      }
      else
      {
        //this.pastYear = this.newdate.getFullYear() - 21;
        this.increaseyear = localStorage.getItem('AppMaleLimit');
        this.pastYear = this.newdate.getFullYear() - this.increaseyear;
      }

      this.birthdate.reset();
      this.newdate.setFullYear(this.pastYear);
      this.date2 = this.convertdate(this.newdate); 
      
      var Difference_In_Time = new Date(this.date1).getTime() - new Date(this.date2).getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
      this.maxDate.setDate(this.maxDate.getDate() - Difference_In_Days);   
      this.today = new Date();
    }


    defaultDate(checkgender)
    {
      this.date1 = this.convertdate(new Date()); 
      this.newdate = new Date();
      this.maxDate = new Date();
      if(checkgender=="Female")
      {
        //this.pastYear = this.newdate.getFullYear() - 18;
        this.increaseyear = localStorage.getItem('AppFemaleLimit');
        this.pastYear = this.newdate.getFullYear() - this.increaseyear;
      }
      else
      {
        //this.pastYear = this.newdate.getFullYear() - 21;
        this.increaseyear = localStorage.getItem('AppMaleLimit');
        this.pastYear = this.newdate.getFullYear() - this.increaseyear;
      }
      this.newdate.setFullYear(this.pastYear);
      this.date2 = this.convertdate(this.newdate); 
      
      var Difference_In_Time = new Date(this.date1).getTime() - new Date(this.date2).getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
      this.maxDate.setDate(this.maxDate.getDate() - Difference_In_Days); 
      this.today = new Date();
    }


  //   handleFileInput(e)
  //   {
  //     console.log("hwyy"+JSON.stringify(e.target.files))
  //     if(e.target.files)
  //   {
  //     this.hiddenFileValue=1;
  //     // this.isUpload = false;
  //     // alert(this.hiddenFileValue)
  //   var reader = new FileReader();
  //   reader.readAsDataURL(e.target.files[0]);
  //   reader.onload=(event:any)=>{
  //     this.imageUrl=event.target.result;
  //     const file = e.target.files[0];
  //     //alert(file)
  //     console.log("hello"+JSON.stringify(file))
  //     this.fourForm.get('profilePhoto"').setValue(file);
      
  //   }
  // }



  //     // this.fileToUpload = file.item(0);
  //     // console.log('finfo',this.fileToUpload.name,this.fileToUpload.size,this.fileToUpload.type);
  //     // const reader = new FileReader();
  //     //     reader.onload = (event:any) => {
  //     //       this.imageUrl = event.target.result;
  //     //       //alert(this.imageUrl)
  //     //       this.hiddenFileValue=1;
  //     //     }
  //     //     reader.readAsDataURL(this.fileToUpload)
  //   }
  onChangeImage(e)
{
  if(e.target.files)
  {
    console.log(e.target.files)
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload=(event:any)=>{
      this.imageUrl=event.target.result;
      this.hiddenFileValue=1;
      const file = e.target.files[0];
      this.fourForm.get('profilePhoto').setValue(file);
     
    }
  }
}

    
}
