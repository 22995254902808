






<div class="homeMain">
<div class="headerMain">
    <div class="headerInner">
        <div class="container">
            <div class="logoMainLogo">
                <nav class="navbar navbar-default">
    <div class="container-fluid">

    <div class="navbar-header">
    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>
    <a class="navbar-brand" href="/"><img src="assets/images/br-logo1.png" alt="logo"></a>
    </div>
    
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <!-- *ngIf="!isuserauth" -->
    <ul class="nav navbar-nav navbar-right" >
        <li><a [routerLink]="['/login']">Login</a></li>
        <li>|</li>
        <li><a [routerLink]="['/registration']">Register Free</a></li>
    </ul>


    </div>
    </div>
    </nav>
            </div>
        </div>
    </div>
</div>


<div class="BannerCarouselMain">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">


        <!-- Indicators -->
        <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
        </ol>
    
        <!-- Wrapper for slides -->
        <div class="carousel-inner">

        <div class="item active">
            <img src="assets/images/slide1New.jpg" alt="">
        </div>

        <div class="item ">
            <img src="assets/images/slide3New.jpg" alt="Chania">
        </div>
    
        
    
        

        <div class="banSearchMainDiv">
            <div class="container">
                <h1 class="bannerHeading" data-aos="fade-up"
            data-aos-duration="3000">Search Your <span class="bestText">Best</span> Matches</h1>
                    <div class="bannerInputMain">
                        <div class="row">
                            <div class="col-md-4">
                                <label>I'm looking for a</label>
                                <select class="inputFil inputFilSelect">
                                    <option>I'm looking for a</option>
                                    <option>Bride</option>
                                    <option>Groom</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <label>Aged</label>
                                <select class="inputFil inputFilSelect">
                                    <option>From</option>
                                    <option *ngFor="let a of agelist" value="{{a}}">{{a}}</option>
                                </select>
                            </div>
                    
        
                            <div class="col-md-2">
                                <label>To</label>
                                <select class="inputFil inputFilSelect">
                                    <option>To</option>
                                    <option *ngFor="let b of ageto" value="{{b}}">{{b}}</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <label>And Mother Tongue</label>
                                <select class="inputFil inputFilSelect">
                                    <option>Marathi</option>
                                    <option>Hindi</option>
                                    <option>Telugu</option>
                                    <option>Tamil</option>
                                    <option>Kannada</option>
                                    <option>Malyalam</option>
                                    <option>Gujrathi</option>
                                    <option>Kokani</option>
                                    <option>English</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <button class="bannerSearchBt" (click)="redirectLogin()">Search</button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        </div>




    
        <!-- Left and right controls -->
        <a class="left carousel-control" href="#myCarousel" data-slide="prev">
        <!-- <span class="glyphicon glyphicon-chevron-left"></span> -->
        <i class="fa fa-angle-left glyphicon-chevron-left"></i>
        <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#myCarousel" data-slide="next">
        <!-- <span class="glyphicon glyphicon-chevron-right"></span> -->
        <i class="fa fa-angle-right glyphicon-chevron-right"></i>
        <span class="sr-only">Next</span>
        </a>
    </div>
</div>




  <!-- <div class="bannerMain">



    <div class="bannerOverly"></div>
    <div class="container">
        <h1 class="bannerHeading" data-aos="fade-up"
     data-aos-duration="3000">Search Your <span class="bestText">Best</span> Matches</h1>
            <div class="bannerInputMain">
                <div class="row">
                    <div class="col-md-4">
                        <label>I'm looking for a</label>
                        <select class="inputFil inputFilSelect">
                            <option>I'm looking for a</option>
                            <option>Bride</option>
                            <option>Groom</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label>Aged</label>
                        <select class="inputFil inputFilSelect">
                            <option>From</option>
                            <option *ngFor="let a of agelist" value="{{a}}">{{a}}</option>
                        </select>
                    </div>
             
  
                    <div class="col-md-2">
                        <label>To</label>
                        <select class="inputFil inputFilSelect">
                            <option>To</option>
                            <option *ngFor="let b of ageto" value="{{b}}">{{b}}</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label>And Mother Tongue</label>
                        <select class="inputFil inputFilSelect">
                            <option>Marathi</option>
                            <option>Hindi</option>
                            <option>Telugu</option>
                            <option>Tamil</option>
                            <option>Kannada</option>
                            <option>Malyalam</option>
                            <option>Gujrathi</option>
                            <option>Kokani</option>
                            <option>English</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <button class="bannerSearchBt" (click)="redirectLogin()">Search</button>
                    </div>
                </div>
            </div>
    </div>
  </div> -->
  
  <div class="container" style="text-align: center;">
    <h2 class="mainHeading2">Why <span style="color: #ff006c;">Lingayat Reshimgathi</span> <br/> Should be Your Preferred Matrimony?</h2>
    <div class="whyPrpDivOuter">
        <div class="whyPrpDiv"><i class="fa fa-pencil-square-o"></i> <span> Free <br/> Registration</span></div>
        <div class="whyPrpDiv"><i class="fa fa-users"></i> <span>Unlimited <br/> Profiles</span></div>
        <div class="whyPrpDiv"><i class="fa fa-check"></i> <span>Only Genuine<br/> & Verified Profiles</span></div>
        <div class="whyPrpDiv"><i class="fa fa-heartbeat"></i> <span>Express <br/> Interest Facility</span></div>
        <div class="whyPrpDiv"><i class="fa fa-eye"></i> <span>Who <br/> Viewed my Profile</span></div>
        <div class="whyPrpDiv"><i class="fa fa-user-plus"></i> <span>Who <br/> Shortlisted me</span></div>
    </div>
  </div>
  
  <div class="bodyMain" data-aos="fade-up"
     data-aos-duration="2000">
    <div class="container">
        <div class="bodyCont1">
            <div class="row">
                <div class="col-md-4 col-sm-4" (click)="redirect('reg')">
                    <div class="serviceDiv">
                        <div class="serviceIcoDiv"><img src="assets/images/sign-up-icon.png"></div>
                        <h5 class="serviHeading" (click)="redirect('reg')">Sign Up</h5>
                        <p>Register for free & put up your Profile</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4" (click)="redirect('log')">
                    <div class="serviceDiv">
                        <div class="serviceIcoDiv"><img src="assets/images/connect-icon.png"></div>
                        <h5 class="serviHeading" >Connect</h5>
                        <p>Select & Connect with Matches you like</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4" (click)="redirect('log')">
                    <div class="serviceDiv serviceDivLast">
                        <div class="serviceIcoDiv"><img src="assets/images/interact-icon.png"></div>
                        <h5 class="serviHeading">Interact</h5>
                        <p>Become a Premium Member</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  
  
  
  <div class="successMain">

    <div class="container">
        <h2 class="mainHeading">Success Stories</h2>
    <h4 class="subHeading">have a look at some of sucess stories from {{sitetitle}}</h4>
        <div class="homeSlderMain">
            <div >
                <div #container>
                    <ng-image-slider [images]="imageObjectval" [imagePopup]="false" [manageImageRatio]="false"
                    [imageSize]="{width: 340, height: 350, space: 3}" >
                    
                    </ng-image-slider>
                </div>
            </div>
        </div>

    </div>


  </div>
  
  <div class="downloadAppMain">
    <div class="downloadDiv">
    <div class="container">
        <div class="downloadDivOuter">
            <button class="dowMoAppBt" (click)="playStore()">Download Mobile App.</button>
        </div>
        <div class="row">
            <div class="col-md-8 col-sm-8">
                 <img class="appLgo" src="assets/images/br-logo3.png">
                 <p class="appDesc">Lingayat Reshimgathi is one of the pioneer's online matrimony website and mobile app
                 to provide a superior matchmaking experience to all Lingayat community and it's sub-caste
             to all over the india and rest of the world. Our purpose is to provide a better services 
         for brides and grooms seeking the perfect life partner through this Lingayat Reshimgathi 
     website or mobile app. <span><a href="#" (click)="playStore()"><img src="assets/images/googlePlay.png"></a></span></p>
  
            </div>
            <div class="col-md-4 col-sm-4" data-aos="fade-left"
     data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <img class="handMo" src="assets/images/hand-mo.png">
            </div>
        </div>
    </div>
    </div>
  </div>
  </div>
  <app-bottom></app-bottom>
  
  
  </div>