import { LoginService } from '../services/login.service';
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { WebsiteConstants } from '.././global/website-constants';
import * as AOS from 'aos';
import { LogoutService } from '../services/logout.service';
import {SuccessStoryServiceService} from '../services/success-story-service.service'

declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  logspinner:boolean; succStory:any; imageObjectval : Array<object> = [] ;
 
  agelist:any=[]; ageto:any=[]; link:any; downloadlink:any; 
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;
  usersession:any; candidatename:any; memberOtp:any; photopath:any;
  isuserauth:any=false;
  constructor(private route: Router,private service:LoginService,private logoutservice:LogoutService, private story : SuccessStoryServiceService) { }

  ngOnInit(): void {


    $('.carousel').carousel({
  interval: 2000
})


    this.usersession = localStorage.getItem("userid");
    this.candidatename = localStorage.getItem("UserFullName");
    this.memberOtp = localStorage.getItem("MemberOTPVerified");
    this.photopath = this.masterUrl;
    if(this.usersession)
    {
      this.isuserauth = true;
    }


    if(this.usersession && this.memberOtp==1)
    {
      let link = ['/my-matches'];
      this.route.navigate(link);
    }
    else
    {
    AOS.init();
    this.displayAppVersion();
    for(let i=21;i<=50;i++)
      {
        this.agelist.push(i)
      }

      for(let j=21; j<=50;j++)
      {
        this.ageto.push(j);
      }

    }

    this. successStoryHash();
  }

  redirectLogin()
  {
    let link = ['/login'];
    this.route.navigate(link);
  }

  redirect(flag)
  {
    
    if(flag=='reg')
    {
       this.link = ['/registration'];
    }
    else
    {
       this.link = ['/login'];
    }
    this.route.navigate(this.link);
  }
  

  logout()
  {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    let loggedoutuser = localStorage.getItem('UserMemberID');
    body.append('LoggedUserid', loggedoutuser);

    this.logoutservice.logoutUrl(body,headers).subscribe((Response) =>{
    localStorage.clear();
    let link = ['/login'];
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate(link);
       
    });

    })
   

  }

  displayAppVersion()
  {
    let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('CasteID', '5');

          this.service.appversionUrl(body,headers).subscribe(Result =>{
            console.log(Result)
          if(Result['status']==1) 
          { 
               localStorage.setItem('DownloadLink',Result['data'][0].AppURL); 
          } 


        })
  }


  playStore()
  {
        let openLink = localStorage.getItem('DownloadLink')
        window.open(openLink);
  }


  successStoryHash()
  {
    this.logspinner = true
    let headers = new Headers({'Content-Type' : 'application/json'})
   let body = new FormData

   return this.story.successStoryPost(body,headers).subscribe(ResponceData =>{

    ResponceData['data'].map(element => {
    this.succStory = element;
    this.logspinner = false
    let obj = {};
   
      
      obj['title'] = this.succStory.SName+'\n'+this.succStory.SDescription;
      obj['thumbImage'] = this.photopath+ this.succStory.SPhoto;
      
      this.imageObjectval.push(obj);

    });
    

   })
   
  }

  

}
