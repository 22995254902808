<div class="innerPagesMain">
  <div class="homeMain">
      <app-home-top></app-home-top>
  

  <div class="innerPageBannerMain">
      <h3>About Lingayat Reshimgathi </h3>
  </div>
  <div class="innerPageBodyMain">
      
      <div class="container">
          <div class="col-md-12">
            <div class="contentDivMain abutmain">
              <span class="aboutPara">
                <!-- <b style="float: none;">Mr. Sachin Bidwai</b>  -->
                Lingayat Reshimgathi established in 2017 and has, since its inception, worked with passion, in the field of match-making with a gentle touch. lingayatreshimgathi.com and Android mobile app is one of the pioneer online platforms to provide a superior matchmaking experience to all Lingayat community and its sub-caste all over India and worldwide. 

              </span>
              <span class="aboutPara">
                Lingayat Reshimgathi strongly belief that Marriage is not only a ceremony but its Sanskar and Birth of new beautiful relation between two families. Lingayat Reshimgathi provides your number of services through its online mobile mantrimony app which helps to find your perfect match. Our purpose is to provide best quality services for brides and grooms seeking the perfect life partner.
              </span>

              <span class="aboutPara">
                You can register for Free and search according to your specific criteria on age, height, profession, income, location and much more – on your computer tablet or mobile.
              </span>
            </div>
            <div class="contentDivMain abutmain">
              <h3>About Sachin Bidwai</h3>
              <div class="aboutProDiv">
                <img src="../../assets/images/sachinbidwai.jpeg"/>
              </div>
              <span class="aboutPara">
                Sachin Bidwai is the Technical Head of Lingayat Reshimgathi online platform service. He completed his graduation from well-known college and DAC from CDAC. He looking after the Technical and also likes to interact with people, youngsters, like to hear from parents of bride and grooms. From interaction with parents, He realised how important is to start a platform which will provide number of matches making at one click from where one can choose perfect life partner for self and Dear ones. He has been instrumental launching the Lingayat Reshimgathi mobile app and website. His goal is to provide best quality service to Lingayat community. He has networked with prospective brides and grooms, young married couples, and parents of prospective brides and grooms and has made it his mission to simplify the process of finding the right match.
              </span>
            </div>
          </div>


      </div>

      <!-- <section class="image-section m-0 p-0 image-bg-2 section-main">
          <div class="container-fluid p-0 m-0">
              <div class="bg-overlay-dark"></div>
              <div class="content">
                  <h1>beautiful template <span class="colorTxt">in-two</span></h1>
                  <p>you will get a short idea about our daily working process</p>
              </div>
          </div>

      </section> -->
  </div>

  <app-bottom></app-bottom>
</div>
</div>
